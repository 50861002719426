import styled from 'styled-components';

export const GlobalSection = styled.section`
	display: flex;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
`;
export const Box = styled.div`
	margin: auto;
`;

export const SectionTitle = styled.h2`
	font-family: ${({ theme }) => theme.fonts.title.bold};
	font-size: 20px;
	line-height: 1.35;
	text-align: center;
	margin-bottom: 40px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
`;

export const Link = styled.a`
	margin-left: auto;
	margin-right: auto;
	font-family: ${({ theme }) => theme.fonts.descr.semiBold};
	font-size: 16px;
	padding: 14px 24px;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.colors.primary};
	${({ theme }) => theme.device.tablet} {
		padding: 14px 38px;
	}
	${({ theme }) => theme.device.default} {
		padding: 14px 64px;
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const ButDiv = styled.div`
	text-align: center;
`;
