import styled from 'styled-components';
import Container from '../../Container';
import bcg from 'image/hero/hero_bg.svg';
// import DownloadLink from '../DownloadLink';

export const HeroSection = styled.section`
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	background-image: url(${bcg});
	margin-bottom: 90px;

	${({ theme }) => theme.device.tablet} {
		padding-top: 100px;
		margin-bottom: 100px;
	}
	${({ theme }) => theme.device.default} {
		padding-top: 120px;
		margin-bottom: 120px;
	}
	${({ theme }) => theme.device.desktop} {
		padding-top: 180px;
		margin-bottom: 150px;
	}
`;
export const HeroImg = styled.img`
	width: 100%;
	height: 'auto';
`;
export const HeroContainer = styled(Container)`
	width: 327px;
	${({ theme }) => theme.device.tablet} {
		width: 620px;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
	}
	${({ theme }) => theme.device.default} {
		width: 835px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 1230px;
	}
`;

export const HeroBox = styled.div`
	text-align: center;
	${({ theme }) => theme.device.desktop} {
		width: 475px;
		text-align: left;
	}
`;

export const GlobalBox = styled.div`
	padding-right: 0px;
	padding-left: 0px;
	text-align: left;
	${({ theme }) => theme.device.tablet} {
		width: 309px;
		margin: 0 auto;
	}
	${({ theme }) => theme.device.default} {
		width: 413px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 579px;
	}
`;

export const HeroThumb = styled.div`
	width: auto;
	margin: 0 auto;
	margin-bottom: 32px;
	${({ theme }) => theme.device.tablet} {
		width: 298px;
	}
	${({ theme }) => theme.device.default} {
		width: 417px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 615px;
	}
`;

export const HeroTitle = styled.h1`
	${({ theme }) => theme.testFonts.mobile.h1};
	margin-bottom: 16px;
	margin-top: 32px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h1};
		margin-bottom: 8px;
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h1};
		margin-top: 30px;
		margin-bottom: 24px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h1};
		margin-bottom: 16px;
	}
`;

export const HeroSectionTitleSub = styled.h3`
	font-family: ${({ theme }) => theme.fonts.title.semibold};
	font-weight: 600;
	font-size: 14px;
	line-height: 1, 4;
	text-align: start;
	margin: auto;
	margin-bottom: 16px;
	color: ${({ theme }) => theme.colors.primary};
	${({ theme }) => theme.device.tablet} {
		font-size: 19px;
	}
	${({ theme }) => theme.device.default} {
		font-size: 25px;
		margin-bottom: 24px;
	}
	${({ theme }) => theme.device.desktop} {
		font-size: 30px;
	}
`;

export const HeroDiscription = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	margin-bottom: 10px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
		margin-bottom: 18px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
		margin-bottom: 20px;
	}
`;

export const HeroLink = styled.button`
	border: 2px solid #303030;
	border-radius: 4px;
	padding: 14px 54px;
`;

export const DisplayPointer = styled.span`
	cursor: pointer;
`;
