import { useFormik } from 'formik';
import * as yup from 'yup';

const validFileFormat = [
	'application/pdf',

	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',

	'text/plain',

	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

	'image/jpg',
	'image/jpeg',
	'image/png',
	'image/gif',

	'video/mp4',
	'video/quicktime',
];

const validator = {
	name: yup
		.string()
		.trim()
		.matches(/^[\p{L}\s'-]+$/u, {
			message: 'Invalid characters not allowed',
		})
		.required('Name is a required field'),
	email: yup
		.string()
		.trim()
		.email('Email format is invalid')
		.required('Email is a required field'),
	description: yup
		.string()
		.trim()
		.matches(/^[\p{L}\s\d\-?'.,]+$/u, {
			message: 'Please enter a brief description of your request',
		})
		.required('Description is a required field'),
	subject: yup
		.string()
		.trim()
		.matches(/^[\p{L}\s\d\-?'.,]+$/u, {
			message: 'Invalid characters not allowed',
		})
		.required('Subject is a required field'),
	topic: yup
		.string()
		.trim()
		.matches(/^[\p{L}\s'-]+$/u, {
			message: 'Invalid characters not allowed',
		})
		.required('Topic is a required field'),
	attachment: yup
		.mixed()
		.test(
			'fileFormat',
			'Sorry, this file format is not supported. We accept: PDF, PPT, TXT, DOC, DOCX, JPG, JPEG, PNG, GIF, MP4, MOV',
			(value) => !value || (value && [...validFileFormat].includes(value.type))
		)
		.optional()
		.test(
			'fileSize',
			'Sorry, this file is too large. Max size is 20MB',
			(value) => !value || (value && value.size <= 20 * 1024 * 1024)
		),
};

export const useFormValidator = (initialValues, onSubmit) => {
	const customValidator = {};
	let keys = Object.keys(initialValues);
	for (let key of keys) {
		if (!validator[key]) {
			throw new Error(`Debugging: ${key} not found`);
		}
		customValidator[key] = validator[key];
	}
	const validationSchema = yup.object().shape({ ...customValidator });
	const formik = useFormik({
		initialValues: {
			...initialValues,
		},
		validationSchema,
		validateOnBlur: true,
		onSubmit,
	});
	return formik;
};
