import Helmet from 'react-helmet';

export const MatomoScript = ({ location }) => (
	<Helmet>
		<script type="text/javascript" location={location ? location.key : ''}>
			{`
                var _paq = window._paq = window._paq || [];
                /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                _paq.push(['trackPageView']);
                _paq.push(['enableLinkTracking']);
                (function() {var u="https://seeyou.matomo.cloud/";
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', '1']);
                var d=document, 
                g=d.createElement('script'), 
                s=d.getElementsByTagName('script')[0];g.async=true; 
                g.src='//cdn.matomo.cloud/seeyou.matomo.cloud/matomo.js';
                s.parentNode.insertBefore(g,s);})();
            `}
		</script>
	</Helmet>
);

export const GoogleAnalytics = ({ location }) => (
	<Helmet>
		<script
			async
			src="https://www.googletagmanager.com/gtag/js?id=G-2278FR98P6"
			location={location ? location.key : ''}
		></script>
		<script type="text/javascript" location={location ? location.key : ''}>
			{`
                <!-- Global site tag (gtag.js) - Google Analytics -->
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-2278FR98P6');
            `}
		</script>
	</Helmet>
);

export const GooglTagManager = ({ location }) => (
	<Helmet>
		<script>
			{`
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PRZHW6R');
    `}
		</script>
		<noscript>
			{`
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PRZHW6R"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`}
		</noscript>
	</Helmet>
);

export const LinkedinTracker = ({ location }) => (
	<Helmet>
		<script type="text/javascript">
			{`
             _linkedin_partner_id = "4898529"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);
             `}
		</script>
		<script type="text/javascript">
			{` (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk); 
             `}
		</script>
		<noscript>
			{`
                <img
                height="1"
                width="1"
                style="display:none;"
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=4898529&fmt=gif"
            />
            `}
		</noscript>
	</Helmet>
);

export const HotjarTracker = ({ location }) => (
	<Helmet>
		<script>
			{`
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2818121,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `}
		</script>
	</Helmet>
);
