import Helmet from 'react-helmet';
import Address from 'components/Address';
import FAQ from 'components/FAQ/FAQ';
import Main from 'components/Main';
import { ScrollToTop } from 'hooks/ScrollToTop';
import Acord from 'components/Accordion';

const Help = () => {
	ScrollToTop();
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu/faq" />
			</Helmet>
			<Main>
				<FAQ />
				<Acord />
			</Main>
			<Address />
		</>
	);
};

export default Help;
