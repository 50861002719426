import { Routes, Route } from 'react-router-dom';
import Layout from '../../components/Layout';
import About from '../../pages/About';
// import Products from '../../pages/Products';
import Home from '../../pages/Home';
import Home1 from '../../pages/Home/Home_1';
import Home2 from '../../pages/Home/Home_2';
import Home3 from '../../pages/Home/Home_3';
import Home4 from '../../pages/Home/Home_4';
// import Ownership from '../../pages/Ownership';
import Help from '../../pages/Help';
import {
	Download,
	DownloadMac,
	DownloadWindows,
	DownloadApps,
} from '../../pages/Download';
// import Release from '../../pages/Release';
import Privacy from '../../pages/Privacy';
import Terms from '../../pages/Terms';
import Report from '../../pages/Report';
import Suggest from '../../pages/Suggest';
import Zoom from '../../pages/Zoom';
import Webex from '../../pages/Webex';
import Google from '../../pages/Google/Google';
import Microsoft from '../../pages/MicrosoftTeams/Microsoft';
import Bluejeans from '../../pages/Bluejeans/Bluejeans';
import Star from '../../pages/StarLeaf/StarLeaf';
import Huawei from '../../pages/Huawei/Huawei';
// import Request from '../../pages/Request';
import Delete from '../../pages/DeleteAccount';
import End from '../../pages/EndEncryption';
// import Problem from '../../pages/Problem';
import Uninstaller from 'pages/Uninstaller';
import PageNotFound from 'pages/PageNotFound';

function App() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home />} />
				<Route path="home" element={<Home />} />
				<Route path="/1" element={<Home1 />} />
				<Route path="/2" element={<Home2 />} />
				<Route path="/3" element={<Home3 />} />
				<Route path="/4" element={<Home4 />} />
				{/* <Route path="co-ownership" element={<Ownership />} /> */}
				<Route path="about" element={<About />} />
				<Route path="faq" element={<Help />} />
				{/* <Route path="downloads" element={<Download />} />
				<Route path="downloads/mac" element={<DownloadMac />} />
				<Route path="downloads/windows" element={<DownloadWindows />} />
				<Route path="downloads/apps" element={<DownloadApps />} /> */}
				{/* <Route path="release" element={<Release />} /> */}
				<Route path="terms" element={<Terms />} />
				<Route path="problem" element={<Report />} />
				<Route path="feature" element={<Suggest />} />
				<Route path="privacy" element={<Privacy />} />
				{/* <Route path="providers-zoom" element={<Zoom />} />
				<Route path="providers-webex" element={<Webex />} />
				<Route path="providers-google" element={<Google />} />
				<Route path="providers-starleaf" element={<Star />} />
				<Route path="providers-microsoft" element={<Microsoft />} />
				<Route path="providers-bluejeans" element={<Bluejeans />} />
				<Route path="providers-huawei" element={<Huawei />} /> */}
				{/* <Route path="request-access" element={<Request />} /> */}
				<Route path="delete-account" element={<Delete />} />
				<Route path="end-to-end-encryption" element={<End />} />
				{/* <Route path="problem2" element={<Problem />} /> */}
				<Route path="uninstall" element={<Uninstaller />} />
			</Route>
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
}

export default App;
