import { usePlatformSelector } from 'hooks/PlatformSelector';
import {
	DownloadList,
	DownloadItem,
	DownloadLink,
	DownloadButton,
	PopupBox,
	PopLink,
} from './DesktopDownloadLinks.styled';

import Mac from 'image/Button/Mac.svg';
import Win from 'image/Button/Win.svg';

const DesktopDownloadLinks = () => {
	const os = usePlatformSelector();
	return (
		<DownloadList>
			{os === 'mac' && (
				<>
					<DownloadItem>
						<DownloadLink
							id="body_mac_regular_download"
							className="gtm_download_tracker"
							title="Body Mac Regular Download"
							href={`${process.env.REACT_APP_DOWNLOAD_ENDPOINT}/desktop/mac/SEEYOU-latest.dmg`}
						>
							<img
								src={Mac}
								alt="Download for Regular Mac"
								style={{ top: '-2px', position: 'relative' }}
							></img>
							<p style={{ marginLeft: '10px' }}>Download for Regular Mac</p>
						</DownloadLink>
					</DownloadItem>
					<DownloadItem>
						<DownloadLink
							id="body_mac_arm_download"
							className="gtm_download_tracker"
							title="Body Mac arm64 Download"
							href={`${process.env.REACT_APP_DOWNLOAD_ENDPOINT}/desktop/mac/SEEYOU-latest-arm64.dmg`}
						>
							<img
								src={Mac}
								alt="Download for Mac M1/M2"
								style={{ top: '-2px', position: 'relative' }}
							></img>
							<p style={{ marginLeft: '10px' }}>Download for Mac M1/M2</p>
						</DownloadLink>
					</DownloadItem>
				</>
			)}
			{os !== 'mac' && (
				<DownloadItem>
					<DownloadLink
						id="body_windows_download"
						className="gtm_download_tracker"
						title="Body Windows Download"
						href={`${process.env.REACT_APP_DOWNLOAD_ENDPOINT}/desktop/windows/SEEYOU-latest.exe`}
					>
						<img src={Win} alt="Download for Windows"></img>
						<p style={{ marginLeft: '10px' }}>Download for Windows</p>
					</DownloadLink>
				</DownloadItem>
			)}

			{os !== 'win' && os !== 'mac' && (
				<DownloadItem>
					<DownloadButton id="body_mac_download">
						<img
							src={Mac}
							alt="Download for Mac"
							style={{ top: '-2px', position: 'relative' }}
						></img>
						<p style={{ marginLeft: '10px' }}>Download for Mac</p>
						<PopupBox>
							<PopLink
								id="body_mac_regular_download"
								className="gtm_download_tracker"
								title="Body Mac Regular Download"
								href={`${process.env.REACT_APP_DOWNLOAD_ENDPOINT}/desktop/mac/SEEYOU-latest.dmg`}
							>
								Download for Regular Mac
							</PopLink>
							<PopLink
								id="body_mac_arm_download"
								className="gtm_download_tracker"
								title="Body Mac arm64 Download"
								href={`${process.env.REACT_APP_DOWNLOAD_ENDPOINT}/desktop/mac/SEEYOU-latest-arm64.dmg`}
							>
								Download for Mac M1/M2
							</PopLink>
						</PopupBox>
					</DownloadButton>
				</DownloadItem>
			)}
		</DownloadList>
	);
};

export default DesktopDownloadLinks;
