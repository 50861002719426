import { useLocation } from 'react-router-dom';
import {
	ChakraProvider,
	Box,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
} from '@chakra-ui/react';
import { MinusIcon, AddIcon } from '@chakra-ui/icons';
import React, { useState, useEffect } from 'react';
// import GlobalImage from '../GlobalImage';
import Container from '../Container/Container.styled';
import { Questions, Answer, Section } from './Accordion.styled';
import { theme } from 'constants/theme';
import { scrollTo } from 'hooks/ScrollToTop';

import { faqs } from 'data/faq';

const CustomAccordion = (props) => {
	const {
		id,
		question,
		answer,
		AnswerComponent,
		style = {},
		defaultExpand,
		setElementReady,
	} = props;

	useEffect(() => {
		if (defaultExpand) {
			setElementReady(true);
		}
	}, [defaultExpand]);

	if (question && (answer || AnswerComponent))
		return (
			<Accordion
				allowMultiple
				paddingBottom="16px"
				style={{ ...style }}
				id={id}
				defaultIndex={defaultExpand ? [0] : [1]}
			>
				<AccordionItem
					border={'none'}
					borderRadius="12px"
					backgroundColor="#ECF1FE"
				>
					{({ isExpanded }) => (
						<>
							<h2>
								<AccordionButton
									_hover={{
										backgroundColor: 'transparent',
										boxShadow: 'none',
									}}
									_focus={{
										boxShadow: 'none',
									}}
									paddingRight="40px"
								>
									<Box flex="1" textAlign="left">
										<Questions>{question}</Questions>
									</Box>
									{isExpanded ? (
										<MinusIcon
											fontSize="12px"
											color={theme.colors.prim_fifteen}
										/>
									) : (
										<AddIcon
											fontSize="12px"
											color={theme.colors.prim_fifteen}
										/>
									)}
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								<Answer>
									{answer && <p>{answer}</p>}
									{AnswerComponent && <AnswerComponent />}
								</Answer>
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			</Accordion>
		);
};

function Acord() {
	const { hash } = useLocation();
	const [defaultFaq, updateDefaultFaq] = useState('');
	const [elementReady, setElementReady] = useState(false);
	const [qList, updateQList] = useState([]);
	useEffect(() => {
		if (elementReady) {
			scrollTo(`#faq-${defaultFaq}`);
		}
	}, [elementReady]);
	useEffect(() => {
		const questions = Object.values(faqs);
		updateQList(questions);
	}, [defaultFaq]);

	useEffect(() => {
		if (hash) {
			const id = hash.replace('#', '');
			updateDefaultFaq(id);
		}
	}, []);
	return (
		<Section>
			<Container>
				<ChakraProvider>
					<Box>
						{qList.map(({ tag, answer, question, AnswerComponent }, index) => (
							<CustomAccordion
								setElementReady={setElementReady}
								id={`faq-${tag}`}
								defaultExpand={tag === defaultFaq ? true : false}
								key={index}
								answer={answer}
								question={question}
								AnswerComponent={AnswerComponent}
							/>
						))}
					</Box>
				</ChakraProvider>
			</Container>
		</Section>
	);
}
export default Acord;
