import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	margin-bottom: 28px;
	${({ styles }) => styles}
`;

export const InputWrapper = styled.div`
	width: 24px;
	height: 24px;
	border: 1px solid red;
	border-radius: 100%;
	display: flex;
	align-items: center;
	vertical-align: center;
	border-color: ${({ theme }) => theme.colors.prim_seven};
	& input {
		-webkit-appearance: none;
		appearance: none;
		margin: auto;
		width: 100%;
		height: 100%;
		cursor: pointer;
		&:after {
			display: block;
			content: '';
			margin: auto;
			width: 40%;
			height: 40%;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			border-radius: 100%;
		}
		&:checked:after {
			background: ${({ theme }) => theme.colors.prim_sixteen};
		}
		&:checked:hover:after {
			opacity: 50%;
			background: ${({ theme }) => theme.colors.prim_sixteen};
		}
		&:hover:after {
			background: ${({ theme }) => theme.colors.prim_seven};
		}
	}
	${({ styles }) => styles}
`;

export const Label = styled.label`
	${({ theme }) => theme.tags.body.mobile.regular}
	color: ${({ theme }) => theme.colors.prim_sixteen};
	margin: auto 0;
	margin-left: 10px;
	${({ theme }) => theme.device.tablet} {
		margin-left: 18px;
	}
	${({ styles }) => styles}
`;
