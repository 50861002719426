import styled from 'styled-components';

export const Section = styled.section`
	margin-bottom: 80px;

	${({ theme }) => theme.device.tablet} {
		margin-bottom: 80px;
	}
	${({ theme }) => theme.device.default} {
		margin-bottom: 180px;
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
export const Questions = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.bold};
	text-align: left;
	padding-top: 16px;
	padding-bottom: 16px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.bold};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.bold};
		padding-top: 32px;
		padding-bottom: 32px;
		padding-left: 30px;
		padding-right: 40px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.bold};
	}
`;
export const Answer = styled.div`
	${({ theme }) => theme.device.default} {
		padding-top: 2px;
		padding-bottom: 32px;
		padding-left: 30px;
		padding-right: 40px;
	}
	p,
	ol {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
		text-align: left;
		color: ${({ theme }) => theme.colors.prim_fifteen};
		${({ theme }) => theme.device.tablet} {
			${({ theme }) => theme.testFonts.tablet.body.regular};
		}
		${({ theme }) => theme.device.default} {
			${({ theme }) => theme.testFonts.mDesktop.body.regular};
		}
		${({ theme }) => theme.device.desktop} {
			${({ theme }) => theme.testFonts.desktop.body.regular};
		}
	}
`;
export const ShowMore = styled.button`
	${({ theme }) => theme.testFonts.desktop.header.bold};
	color: ${({ theme }) => theme.colors.primary};
`;

export const PartnersDiv = styled.div`
	padding-top: 20px;
	// width: 333px;
	${({ theme }) => theme.device.tablet} {
		// width: 700px;
		// margin-left: 20px;
	}
`;
export const PartnersUl = styled.ul`
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
`;
export const PartnersLi = styled.li`
	padding: 0;
	margin-bottom: 24px;
	margin-right: 12px;

	transform: scale(80%);
	${({ theme }) => theme.device.tablet} {
		transform: scale(100%);
		margin-bottom: 32px;
		&:not(:last-child) {
			margin-right: 24px;
		}
	}
`;
export const PartnerP = styled.p`
	&:not(:last-child) {
		margin-bottom: 15px;
	}
`;
export const Link = styled.a`
	color: ${({ theme }) => theme.colors.primary};
`;
