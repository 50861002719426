export const theme = Object.freeze({
	colors: {
		white: '#ffffff',
		black: '#333333',
		dark: '#000000',
		black_btn: '#303030',
		red: '#f44336',
		blue: '#D9DBE9',
		green: '#00875E',
		primaryText: '#404040',
		secondaryText: '#757575',
		body_bg: '#FAFAFA',
		tomato: 'tomato',
		footer_bg: '#464648',
		link: '#007aff',
		input_bg: '#f1f1f5',
		input_bg2: '#FAFAFD',
		placeholder: '#6E7191',
		privacy_bg: '#E3E3EB',
		primary: '#7058FF',
		body_svg: '#f9f9f9',
		orange: '#FCB601',
		grey_off: '#C9CAD4',
		grey_purple: '#A0A3BD',

		prim_three: '#FFFFFF',
		prim_five: '#A0A3BD',
		prim_seven: '#E3E3EB',
		prim_ten: '#7058FF',
		prim_fifteen: '#6870B5',
		prim_sixteen: '#1D224F',
		prim_seventeen: '#464C84',
		prim_eighteen: '#F2F2FA',
		prim_twenty_one: '#8C91C1',
		prim_twenty_two: '#E2DEFF',

		sec_eleven: '#EA4038',
	},
	size: {
		mobile: '375px',
		tablet: '768px',
		default: '960px',
		desktop: '1306px',
	},
	device: {
		mobile: `@media screen and (min-width:375px)`,
		mobileMax: `@media screen and (max-width:767px)`,
		tablet: `@media screen and (min-width: 768px)`,
		default: `@media screen and (min-width: 960px)`,
		defaultMax: `@media screen and (max-width: 1305px)`,
		desktop: `@media screen and (min-width: 1306px)`,
	},
	fonts: {
		title: {
			semiBold: 'Jost_SemiBold',
			bold: 'Jost_Bold',
		},
		descr: {
			regular: 'OpenSans_Regular',
			semiBold: 'OpenSans_SemiBold',
			bold: 'OpenSans_Bold',
		},
		body: {
			regular: 'Noto Sans Display',
		},
	},
	spacing: (value) => `${4 * value}px`,
	tags: {
		h1: {
			mobile: `
			font-family: Jost_SemiBold;
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 130%;
			`,
			tablet: `
			font-family: Jost_SemiBold;
			font-style: normal;
			font-weight: 600;
			font-size: 26px;
			line-height: 130%;
			`,
			mdesktop: `
			font-family: Jost_SemiBold;
			font-style: normal;
			font-weight: 600;
			font-size: 44px;
			line-height: 120%;
			`,
			desktop: `
			font-family: Jost_SemiBold;
			font-style: normal;
			font-weight: 600;
			font-size: 44px;
			line-height: 120%;
			`,
		},
		h2: {
			mdesktop: `
			font-family: Jost_SemiBold;
			font-style: normal;
			font-weight: 600;
			font-size: 25px;
			line-height: 130%;
			`,
			desktop: `
			font-family: 'Jost_SemiBold';
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 130%;
			`,
		},
		body: {
			desktop: {
				regular: `
				font-family: 'OpenSans_Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 140%;
			`,
			},
			mdesktop: {
				regular: `
				font-family: 'OpenSans_Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 140%;
			`,
			},
			tablet: {
				regular: `
				font-family: 'OpenSans_Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 130%;
			`,
			},
			mobile: {
				regular: `
				font-family: 'OpenSans_Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 140%;
			`,
			},
		},
	},
	testFonts: {
		desktop: {
			h1: {
				fontFamily: 'Jost_SemiBold',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '44px',
				lineHeight: '52.8px',
			},
			h2: {
				fontFamily: 'Jost_SemiBold',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '30px',
				lineHeight: '39px',
			},
			header: {
				regular: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '18px',
					lineHeight: '27px',
				},
				bold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: '18px',
					lineHeight: '27px',
				},
			},
			body: {
				regular: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '18px',
					lineHeight: '25.2px',
				},
				semiBold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: '18px',
					lineHeight: '23.4px',
				},
				bold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '700',
					fontSize: '18px',
					lineHeight: '23.4px',
				},
				small: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '12px',
					lineHeight: '15.6px',
				},
			},
			button: {
				fontFamily: 'OpenSans_Regular',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '16px',
				lineHeight: '20px',
			},
			dropDown: {
				fontFamily: 'OpenSans_Regular',
				fontStyle: 'normal',
				fontWeight: '400',
				fontSize: '14px',
				lineHeight: '18.2px',
			},
		},
		mDesktop: {
			h1: {
				fontFamily: 'Jost_SemiBold',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '30px',
				lineHeight: '39px',
			},
			h2: {
				fontFamily: 'Jost_SemiBold',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '25px',
				lineHeight: '32.5px',
			},
			header: {
				regular: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '14px',
					lineHeight: '21px',
				},
				bold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: '14px',
					lineHeight: '21px',
				},
			},
			body: {
				regular: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '16px',
					lineHeight: '22.4px',
				},
				semiBold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: '16px',
					lineHeight: '22.4px',
				},
				bold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '700',
					fontSize: '16px',
					lineHeight: '22.4px',
				},
				small: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '12px',
					lineHeight: '15.6px',
				},
			},
			button: {
				fontFamily: 'OpenSans_Regular',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '16px',
				lineHeight: '20px',
			},
			dropDown: {
				fontFamily: 'OpenSans_Regular',
				fontStyle: 'normal',
				fontWeight: '400',
				fontSize: '14px',
				lineHeight: '18.2px',
			},
		},
		tablet: {
			h1: {
				fontFamily: 'Jost_SemiBold',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '26px',
				lineHeight: '33.8px',
			},
			h2: {
				fontFamily: 'Jost_SemiBold',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '25px',
				lineHeight: '32.5px',
			},
			header: {
				regular: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '14px',
					lineHeight: '21px',
				},
				bold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: '14px',
					lineHeight: '21px',
				},
			},
			body: {
				regular: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '14px',
					lineHeight: '18.2px',
				},
				semiBold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: '14px',
					lineHeight: '18.2.4px',
				},
				bold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '700',
					fontSize: '14px',
					lineHeight: '18.2px',
				},
				small: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '9px',
					lineHeight: '11.7px',
				},
			},
			button: {
				fontFamily: 'OpenSans_Regular',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '16px',
				lineHeight: '20px',
			},
			dropDown: {
				fontFamily: 'OpenSans_Regular',
				fontStyle: 'normal',
				fontWeight: '400',
				fontSize: '14px',
				lineHeight: '18.2px',
			},
		},
		mobile: {
			h1: {
				fontFamily: 'Jost_SemiBold',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '30px',
				lineHeight: '39px',
			},
			h2: {
				fontFamily: 'Jost_SemiBold',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '14px',
				lineHeight: '18.2px',
			},
			body: {
				regular: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '14px',
					lineHeight: '19.6px',
				},
				semiBold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: '14px',
					lineHeight: '19.6px',
				},
				bold: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '700',
					fontSize: '14px',
					lineHeight: '19.6px',
				},
				small: {
					fontFamily: 'OpenSans_Regular',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '8px',
					lineHeight: '10.4px',
				},
			},
			button: {
				fontFamily: 'OpenSans_Regular',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '16px',
				lineHeight: '20px',
			},
		},
	},
});
