import styled from 'styled-components';

export const LLC = styled.h3`
	${({ theme }) => theme.testFonts.mobile.body.small};
	color: ${({ theme }) => theme.colors.prim_fifteen};
	margin: 0 auto 40px;
	padding: 0 24px;
	text-align: center;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.small};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.small};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.small};
	}
`;
