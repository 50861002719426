import styled from 'styled-components';
import { device } from '../../constants/mediaSize';

const Container = styled.div`
	padding: 0 15px;
	margin: 0 auto;
	width: 100%;

	${device.mobile} {
		width: 373px;
	}

	${device.tablet} {
		padding: 0px;
		width: 622px;
	}

	${device.default} {
		padding: 0 24px;
		width: 830px;
	}
	${device.desktop} {
		width: 1140px;
	}
`;

export default Container;
