import { Image } from '@chakra-ui/react';
import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { CookieContext, cookieTypes } from '../../hooks/CookieHandler';
import Cookies from 'js-cookie';
import {
	MatomoScript,
	GoogleAnalytics,
	LinkedinTracker,
	// HotjarTracker,
	GooglTagManager,
} from './AdditionalScripts';

import {
	ContainerWrapper,
	CookieContainer,
	Title,
	TitleWrapper,
	DescriptionWrapper,
	Description,
	AccepButton,
	DenyButton,
} from './consent.styled';
import Container from '../Container';
import cookieImage from './../../image/consent/cookie.png';

// Note: Add the script by the category it belongs to. Remove the script from cookieCategories if not used
const cookieCategories = {
	statistics: {
		// GoogleTagManager: () => {
		// 	try {
		// 		GTagManager.initialize({
		// 			gtmId: 'G-2278FR98P6',
		// 		});
		// 		window.dataLayer.push({
		// 			event: 'pageview',
		// 		});
		// 	} catch (e) {
		// 		console.error(e);
		// 	}
		// },
	},
};

const executeScripts = (consent) => {
	cookieTypes.forEach((category) => {
		if (consent[category]) {
			if (!cookieCategories[category]) {
				return;
			}
			const fNames = Object.keys(cookieCategories[category]);
			fNames.forEach((name) => cookieCategories[category][name]());
		}
	});
};

const Consent = () => {
	const { consent, setConsent } = useContext(CookieContext);

	const location = useLocation();
	const [isHidden, setHidden] = useState(true);

	const consentRef = useRef(null);

	const handleClick = (consentGiven) => {
		let categoryConsent;
		if (consentGiven === 'accept') {
			categoryConsent = cookieTypes.reduce(
				(prev, current) => ({
					...prev,
					[current]: true,
				}),
				{}
			);
		} else {
			categoryConsent = cookieTypes.reduce(
				(prev, current) => ({
					...prev,
					[current]: false,
				}),
				{}
			);
		}
		Cookies.set('consent', JSON.stringify(categoryConsent));
		setConsent({ ...categoryConsent });
	};

	useEffect(() => {
		if (isHidden) {
			consentRef.current.style = 'display:none';
		} else {
			consentRef.current.style = 'display:block';
		}
	}, [isHidden]);

	useEffect(() => {
		let consented = Object.keys(consent);
		if (consented.length) {
			executeScripts(consent);
			setHidden(true);
		} else {
			setHidden(false);
		}
	}, [location, consent]);

	return (
		<>
			{consent.thirdParty ? <MatomoScript location={location} /> : ''}
			{consent.statistics ? <GoogleAnalytics location={location} /> : ''}
			{consent.statistics ? <LinkedinTracker location={location} /> : ''}
			{/* {consent.statistics ? <HotjarTracker location={location} /> : ''} */}
			{consent.statistics ? <GooglTagManager location={location} /> : ''}
			<ContainerWrapper ref={consentRef}>
				<Container>
					<CookieContainer>
						<TitleWrapper>
							<Image
								src={cookieImage}
								alt="Cookie consent"
								width={'20px'}
								height={'auto'}
							/>
							<Title>This website uses cookies</Title>
						</TitleWrapper>
						<DescriptionWrapper>
							<Description>
								Cookies are what allow us to improve user experience
							</Description>
							<div>
								{/* {categories.map((item, index) => (
								<input
									key={index}
									type={'checkbox'}
									name={item}
									checked={consent[item]}
									value={item}
									onChange={}
								/>
							))} */}
								<AccepButton onClick={() => handleClick('accept')}>
									Accept
								</AccepButton>
								<DenyButton onClick={() => handleClick('decline')}>
									Deny
								</DenyButton>
							</div>
						</DescriptionWrapper>
					</CookieContainer>
				</Container>
			</ContainerWrapper>
		</>
	);
};

export default Consent;
