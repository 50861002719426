import {
	SectionTitle,
	Box,
	GlobalSection,
	Link,
	ButDiv,
} from './NotFound.styled';

import Container from '../Container';

const OurLeadership = () => {
	return (
		<GlobalSection>
			<Container>
				<Box>
					<SectionTitle>Page Not Found</SectionTitle>
				</Box>
				<ButDiv>
					<Link href="/">Return to Homepage</Link>
				</ButDiv>
			</Container>
		</GlobalSection>
	);
};
export default OurLeadership;
