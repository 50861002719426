import styled from 'styled-components';
import Container from '../../Container';
import LeafProv from 'image/bg/leaf_providers.svg';

export const Section = styled.section`
	padding: 0px;
	margin: auto;
	margin-bottom: 40px;

	${({ theme }) => theme.device.tablet} {
		background-repeat: no-repeat;
		background-position: bottom left;
		background-size: small;
		// background-image: url(${LeafProv});
		margin-bottom: 40px;
	}
	${({ theme }) => theme.device.default} {
		margin-bottom: 50px;
	}
	${({ theme }) => theme.device.desktop} {
		margin-bottom: 70px;
	}
`;

export const ToolbarContainer = styled(Container)`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	${({ theme }) => theme.device.tablet} {
		align-items: start;
		justify-content: space-between;
	}
	${({ theme }) => theme.device.default} {
		align-items: top;
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
export const Tool = styled.img`
	${({ theme }) => theme.device.tablet} {
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const DivImage = styled.div`
	margin: 0 auto;
	width: 128px;
	height: auto;
	order: 1;
	align-self: center;
	display: none;

	${({ theme }) => theme.device.tablet} {
		display: block;
		width: 124px;
	}
	${({ theme }) => theme.device.default} {
		width: 148px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 204px;
	}
`;
export const Img = styled.img`
	margin: 0 auto;
	width: 100%;
	height: auto;
`;

export const Box = styled.div`
	margin: 0 auto;
	width: 327px;
	order: 3;
	// margin-top: 0;
	${({ theme }) => theme.device.tablet} {
		width: 410px;
		order: 2;
	}
	${({ theme }) => theme.device.default} {
		width: 538px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 682px;
	}
`;

export const Title = styled.h1`
	${({ theme }) => theme.testFonts.mobile.h1};
	margin-top: 0px;
	text-align: left;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h1};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h1};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h1};
	}
`;

export const Discription = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	margin-top: 16px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	margin-bottom: 0px;
	text-align: left;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
		text-align: left;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
	}
`;

export const ToolbarThumb = styled.div`
	margin: 0 auto;
	margin-top: 32px;
	margin-bottom: 32px;
	width: 100%;
	max-width: 355px;
	// box-shadow: 0px 15px 20px rgba(112, 88, 255, 0.16);
	// border-radius: 6px;
	order: 2;
	${({ theme }) => theme.device.mobileMax} {
		/* width: 618px; */
		/* max-width: 578px; */
		overflow-x: scroll;

		& Tool {
			/* overflow-x: scroll; */
			max-width: 578px;
		}
	}

	${({ theme }) => theme.device.tablet} {
		margin-bottom: 0px;
		margin-top: 40px;
		max-width: 698px;
		// box-shadow: 0px 30px 37px rgba(112, 88, 255, 0.16);
		// border-radius: 12px;
		order: 3;
	}
	${({ theme }) => theme.device.default} {
		margin-top: 50px;
		max-width: 698px;
	}
	${({ theme }) => theme.device.desktop} {
		margin-top: 42px;
		max-width: 870px;
	}
`;

export const DisplayPointer = styled.span`
	cursor: pointer;
`;
