import { useEffect } from 'react';

export const scrollTo = (id) => {
	const section = document.querySelector(id),
		headerOffset = document.querySelector('header').clientHeight,
		position = section.getBoundingClientRect().top,
		offsetPosition = position + window.pageYOffset - headerOffset;

	window.scrollTo({
		top: offsetPosition,
		behavior: 'smooth',
	});
};

export const ScrollToTop = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	});
};
