import styled from 'styled-components';
import Down from 'image/bg/cloudDown_bg.svg';

export const SupportSection = styled.footer`
	position: fixed;
	bottom: 0;
	padding-top: 15px;
	padding-bottom: 15px;
	width: 100%;
	background: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(48px);
	border: 0.5px solid #eae9ff;

	/* ${({ theme }) => theme.device.tablet} {
    bottom: 0px;
  } */
	${({ theme }) => theme.device.default} {
		padding-top: 25px;
		padding-bottom: 25px;
		/* bottom: 0px; */
	}
	${({ theme }) => theme.device.desktop} {
		padding-top: 30px;
		padding-bottom: 30px;
		/* bottom: 0px; */
	}
`;

export const BackgroundDiv = styled.div`
	${({ theme }) => theme.device.tablet} {
		position: absolute;
		z-index: -1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 150px;
		background-repeat: no-repeat;
		background-position: top left -100px;
		background-size: small;
		background-image: url(${Down});
	}
	${({ theme }) => theme.device.default} {
		height: 175px;
	}
	${({ theme }) => theme.device.desktop} {
		background-position: top left 0;
		height: 220px;
	}
`;

export const DummyComponent = styled.div`
	height: 59px;
	${({ theme }) => theme.device.tablet} {
		height: 76px;
	}
	${({ theme }) => theme.device.default} {
		height: 102px;
	}
	${({ theme }) => theme.device.desktop} {
		height: 120px;
	}
`;

export const Title = styled.h2`
	font-family: ${({ theme }) => theme.fonts.title.semiBold};
	line-height: 1.43;
	color: ${({ theme }) => theme.colors.white};
	text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
	${({ theme }) => theme.device.tablet} {
		font-size: 16px;
	}
	${({ theme }) => theme.device.default} {
		font-size: 18px;
	}
	${({ theme }) => theme.device.desktop} {
		font-size: 26px;
	}
`;

export const SupportContainer = styled.div`
	margin: 0 auto;
	${({ theme }) => theme.device.tablet} {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 670px;
	}
	${({ theme }) => theme.device.default} {
		margin: 0 auto;
		width: 863px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 1088px;
	}
`;

export const Box = styled.div`
	margin-bottom: 30px;
	text-align: left;

	${({ theme }) => theme.device.tablet} {
		margin-bottom: 0px;
		padding-right: 15px;
		width: 170px;
	}
	${({ theme }) => theme.device.default} {
		width: 170px;
		padding-right: 15px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 390px;
		padding-right: 20px;
	}
`;
export const SupportUl = styled.ul`
	display: flex;
	width: 100%;
	justify-content: center;
	${({ theme }) => theme.device.tablet} {
		justify-content: space-between;
	}
`;
export const SupportLi = styled.li`
	height: auto;
	width: 112px;
	&:not(:last-child) {
		margin-right: 18px;
	}
	${({ theme }) => theme.device.tablet} {
		width: 105px;
		&:not(:last-child) {
			margin-right: 16px;
		}
	}
	${({ theme }) => theme.device.default} {
		width: 127.6px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 170px;
	}
`;
export const BoxIMG = styled.div`
	display: flex;
	align-items: center;
	margin: 0 auto;
	${({ theme }) => theme.device.tablet} {
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const Discription = styled.p`
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	font-size: 14px;
	line-height: 1.35;
	margin-bottom: 20px;
	color: ${({ theme }) => theme.colors.privacy_bg};
	${({ theme }) => theme.device.tablet} {
		font-size: 16px;
	}
	${({ theme }) => theme.device.default} {
		font-size: 16px;
	}
	${({ theme }) => theme.device.desktop} {
		font-size: 18px;
	}
`;
export const Discriptionltd = styled.p`
	padding-top: 46px;
	text-align: center;
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	font-weight: 400;
	font-size: 12px;
	line-height: 1.35;
	color: ${({ theme }) => theme.colors.privacy_bg};
	${({ theme }) => theme.device.tablet} {
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const DiscriptionWithMargin = styled(Discription)`
	padding-bottom: 20px;
`;

export const Image = styled.img`
	border-radius: 6px;
	filter: drop-shadow(0px 10.9453px 24.0796px rgba(112, 88, 255, 0.16));
`;

export const PopupButton = styled.button`
	position: relative;
`;

export const PopupBox = styled.div`
	background: #fff;
	box-shadow: 0px 14px 32px rgba(112, 88, 255, 0.16);
	border-radius: 10px;
	display: none;
	flex-direction: column;
	justify-content: space-around;
	padding: 15px;
	position: absolute;
	z-index: 2;
	transform: translateY(-100%);
	top: 0px;
	left: 0;
	width: 206px;
	${PopupButton}:hover & {
		display: flex;
	}
	&:hover {
		display: flex;
	}
`;

export const PopupBoxImage = styled.img`
	margin: auto;
	width: 158px;
	height: auto;
`;

export const PopupBoxText = styled.p`
	${({ theme }) => theme.tags.body.mdesktop.regular}
	font-family: ${({ theme }) => theme.fonts.body.regular};
	color: ${({ theme }) => theme.colors.dark};
`;
export const PopupBoxSubText = styled.p`
	${({ theme }) => theme.tags.body.tablet.regular}
	font-family: ${({ theme }) => theme.fonts.body.regular};
	color: ${({ theme }) => theme.colors.prim_five};
	background: ${({ theme }) => theme.colors.white};
`;

// export const PopupBoxNotch = styled.div`
// 	width: 30px;
// 	height: 30px;
// 	bottom: 0;
// 	left: 50%;
// 	z-index: 1;
// 	transform: translate(-50%, 50%) rotate(45deg);
// 	position: absolute;
// 	background: red;
// `;

export const PopLink = styled.a`
	position: relative;
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	font-size: 14px;
	line-height: 19px;
	margin-bottom: 15px;
	text-align: start;
	width: 100%;
	&.active {
		font-family: ${({ theme }) => theme.fonts.descr.bold};
	}
	&:last-child {
		margin-bottom: 0;
	}
	${({ theme }) => theme.device.tablet} {
		font-size: 14px;
	}
`;
