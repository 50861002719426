import styled from 'styled-components';

export const SectionTitle = styled.h1`
	${({ theme }) => theme.testFonts.mobile.h1};
	text-align: center;
	margin-top: 50px;
	margin-bottom: 8px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h1};
		margin-top: 80px;
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h1};
		margin-top: 100px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h1};
		margin-top: 132px;
	}
`;

export const SectionTitleSub = styled.h3`
	${({ theme }) => theme.testFonts.mobile.h2};
	text-align: center;
	margin-bottom: 34px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		font-size: 25px;
		margin-bottom: 67px;
	}
	${({ theme }) => theme.device.default} {
		font-size: 25px;
		margin-bottom: 58px;
	}
	${({ theme }) => theme.device.desktop} {
		font-size: 30px;
		margin-bottom: 58px;
	}
`;

export const ConferenceTitle = styled.h1`
	${({ theme }) => theme.testFonts.mobile.h1};
	margin-bottom: 16px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h1};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h1};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h1};
	}
`;

export const AboutUl = styled.ul``;

export const ConferenceDiscription = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	margin-bottom: 20px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
		margin-bottom: 30px;
	}
`;
export const ContactLink = styled.a`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	text-decoration-line: underline;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	margin-bottom: 20px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
		margin-bottom: 30px;
	}
`;
export const ConferenceDiscriptionSub = styled.p`
	${({ theme }) => theme.testFonts.tablet.body.small};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	margin-bottom: 20px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.small};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.small};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.small};
		margin-bottom: 30px;
	}
`;

export const ConferenceItem = styled.li`
	margin-bottom: 80px;
	align-items: center;
	display: flex;
	flex-direction: column-reverse;
	${({ theme }) => theme.device.tablet} {
		margin-bottom: 80px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		&:nth-child(2n) {
			flex-direction: row-reverse;
		}
	}

	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
		margin-bottom: 90px;
	}
`;

export const Image = styled.img`
	margin: 0 auto;
	margin-bottom: 15px;
	${({ theme }) => theme.device.tablet} {
	}
	${({ theme }) => theme.device.default} {
		margin-bottom: 0px;
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
export const DivImg = styled.div`
	margin: 0 auto;
	${({ theme }) => theme.device.tablet} {
		padding: 0 15px;
	}
	${({ theme }) => theme.device.default} {
		padding: 0 20px;
	}
	${({ theme }) => theme.device.desktop} {
		padding: 0 40px;
	}
`;
