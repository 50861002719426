import {
	Section,
	HiddenTitle,
	SubTitle,
	Discription,
	// DiscriptionWithMargin,
	ConferenceItem,
	DiscriptionSub,
} from './Conference.styled';

import { Image } from '@chakra-ui/react';

import Container from '../../Container';
import GlobalBox from '../../GlobalBox';
import GlobalImage from '../../GlobalImage';
import SelfieImg from 'image/conference/selfie_loop_tm.png';
import StickyImg from 'image/conference/sticky_note.png';
import Library from 'image/conference/free_virtual_library.png';
import Rewind from 'image/conference/realtime_rewind.png';
import Calls from 'image/conference/truly1.png';
import HomeScreen from 'image/conference/one_stop.png';
// import coOwnership from 'image/conference/free-coowner.png';
import Provider from './../Providers';
const Conference = () => {
	return (
		<Section>
			<Container>
				<HiddenTitle>Conference </HiddenTitle>
				<ul>
					{/* Selfie Loop ™ */}
					<ConferenceItem id="selfie-loop">
						<GlobalImage>
							<Image src={SelfieImg} alt="Selfie in SEEYOU" />
						</GlobalImage>
						<GlobalBox>
							<SubTitle>
								Selfie Loop <sup>TM</sup>
							</SubTitle>
							<Discription>
								SEEYOU provides a real-time virtual camera Selfie Loop
								<sup>TM</sup>. Substituting your camera feed with a selfie video
								when getting a coffee, making a call, or stepping away for a
								second
							</Discription>
						</GlobalBox>
					</ConferenceItem>

					{/* Hyperlinked Notes */}
					<ConferenceItem id="hyperlinked-notes">
						<GlobalImage>
							<Image src={StickyImg} alt="Selfie in SEEYOU" />
						</GlobalImage>
						<GlobalBox>
							<SubTitle>Hyperlinked Notes</SubTitle>
							<Discription>
								On Mac and Windows, SEEYOU provides fully encrypted hyperlinked
								sticky notes. Clicking on any hyperlinked note will
								automatically open any recording in your library at the exact
								time you made the note. If storing the recordings on a free
								YouTube Studio<sup>®</sup> account or one of the upcoming cloud
								alternatives, the library is also fully accessible from your
								Android or iOS devices
							</Discription>
						</GlobalBox>
					</ConferenceItem>

					{/* Free Virtual Library */}
					<ConferenceItem id="library">
						<GlobalImage>
							<Image src={Library} alt="Library" />
						</GlobalImage>
						<GlobalBox>
							<SubTitle>Free Virtual Library</SubTitle>
							<Discription>
								SEEYOU stores your recordings for free and indexes them based on
								your notes, making them intelligent and retrievable based on
								what you deemed essential at the time. The notes are encrypted,
								and the recordings are kept on your computer or in your private
								YouTube Studio<sup>®</sup> library, with more cloud providers
								soon added
							</Discription>
						</GlobalBox>
					</ConferenceItem>

					{/* Uniform Video Conference Booking */}
					<Provider />

					{/* Dummy element */}
					<div></div>

					{/* Real-Time Rewind */}
					<ConferenceItem id="real-time-rewind">
						<GlobalImage>
							<Image src={Rewind} alt="Rewind" />
						</GlobalImage>
						<GlobalBox>
							<SubTitle>Real-Time Rewind</SubTitle>
							<Discription>
								On Mac and Windows, real-time-rewind allows you to go back and
								check past parts while still following the live feed. The rewind
								can be combined* with the Selfie Loop, ensuring no one notices
								that you go back and check earlier parts of a meeting,
								conference, or lecture
							</Discription>
							<DiscriptionSub>
								<sup>*</sup>For now, Intel i3 processors and processors of lower
								capacity cannot simultaneously use rewind and the Selfie Loop.
								This will be resolved in later updates.
							</DiscriptionSub>
						</GlobalBox>
					</ConferenceItem>

					<ConferenceItem>
						<GlobalImage>
							<Image src={Calls} alt="Calls" />
						</GlobalImage>
						<GlobalBox>
							<SubTitle>Truly Private Communication</SubTitle>
							<Discription>
								Chats and group chats are end-to-end encrypted. Video and voice
								calls are fully encrypted. The encryption key is personal and
								kept on your SEEYOU mobile phone app. SEEYOU cannot read the
								content of chats, listen in on your calls, or see with whom you
								are communicating, nor can anyone else. Unlike many other
								providers, this holds for group chats too
							</Discription>
							<DiscriptionSub>
								<sup>*</sup>Double authentications prevent access to your Mac
								and Windows messages or library without you first unlocking your
								desktop device from your mobile phone
							</DiscriptionSub>
						</GlobalBox>
					</ConferenceItem>

					<ConferenceItem>
						<GlobalImage>
							<Image src={HomeScreen} alt="HomeScreen" />
						</GlobalImage>
						<GlobalBox>
							<SubTitle>One-Stop-Shop App Home Screen</SubTitle>
							<Discription>
								The SEEYOU App Home Screen is a one-stop-shop for launching the
								Toolbar, joining upcoming calendar events, accessing your
								library, booking and attending video meetings and online
								classes, and sending and receiving serverless encrypted
								messages, calls, and video calls
							</Discription>
						</GlobalBox>
					</ConferenceItem>

					{/* <ConferenceItem>
						<GlobalImage>
							<Image src={coOwnership} alt="coOwnership" />
						</GlobalImage>
						<GlobalBox>
							<SubTitle>Free Co-Ownership Program</SubTitle>
							<DiscriptionWithMargin>
								SEEYOU will be partly user-owned. An equivalent of 5% of the
								original 2.5 million shares will be gifted to active* users and
								recruiters during the first 25 months from September 1st, 2023
							</DiscriptionWithMargin>
							<DiscriptionSub>(*Final terms to be announced)</DiscriptionSub>
						</GlobalBox>
					</ConferenceItem> */}
				</ul>
			</Container>
		</Section>
	);
};
export default Conference;
