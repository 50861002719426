import {
	Section,
	ToolbarContainer,
	Box,
	Title,
	Img,
	Discription,
	ToolbarThumb,
	DivImage,
	// Tool,
	DisplayPointer,
} from './Toolbar.styled';
import { scrollTo } from '../../../hooks/ScrollToTop';
// import MediaQuery from '../../MediaQuery';
import seemoji from 'image/toolbar/sm1.png';
import toolbar from 'image/toolbar/toolbar1.png';

const Toolbar = () => {
	return (
		<Section id="toolbar">
			<ToolbarContainer>
				<DivImage>
					<Img src={seemoji} alt={seemoji} />
				</DivImage>
				<Box>
					<Title>A Toolbar working with all your Favorite Applications</Title>
					<Discription>
						On Mac and Windows, SEEYOU adds a standard toolbar with the option
						to make hyperlinked notes and store live feeds and recordings you
						watch online. You can also unnoticeably multitask during video
						conferences and lectures using the{' '}
						<DisplayPointer onClick={() => scrollTo('#selfie-loop')}>
							<u>Selfie Loop</u>
							<sup>TM</sup>
						</DisplayPointer>
						. And you can check what was previously said with our{' '}
						<DisplayPointer onClick={() => scrollTo('#real-time-rewind')}>
							<u>real-time rewind</u>
						</DisplayPointer>
						, or by consulting your{' '}
						<DisplayPointer onClick={() => scrollTo('#library')}>
							<u>library</u>
						</DisplayPointer>
						. You can also make encrypted serverless messages, calls, and video
						calls
					</Discription>
				</Box>
				{/* </ToolbarContainer>
			<ToolbarContainer> */}
				<ToolbarThumb>
					<img src={toolbar} alt="toolbar" />
				</ToolbarThumb>
			</ToolbarContainer>
		</Section>
	);
};
export default Toolbar;
