import styled from 'styled-components';
import { device, size } from 'constants/mediaSize';

export const Container = styled.div`
	width: 100%;
	max-width: 100%;
	padding: 0 16px;
	padding-top: 45px;
	margin: 0 auto;
	margin-top: 55px;
	${device.mobile} {
		max-width: ${size.mobile};
	}
	${device.tablet} {
		padding-top: 100px;
		margin-top: 70px;
		max-width: ${size.tablet};
	}
	${device.default} {
		max-width: ${size.default};
	}
	${device.desktop} {
		max-width: 1140px;
	}
`;

export const Section = styled.div`
	display: flex;
	margin: 0 auto;
	justify-content: center;
`;

export const FormWrapper = styled.div`
	width: 100%;
	max-width: 350px;
	border-radius: 8px;
	margin-bottom: 90px;
	${({ theme }) => theme.device.tablet} {
		max-width: 490px;
	}
`;
