import React from 'react';
import { Image } from '@chakra-ui/react';
import {
	PartnersDiv,
	PartnersUl,
	PartnersLi,
	PartnerP,
	Link,
} from 'components/ReportFaq/FaqSection.styled';

import GoogleMeet from 'image/FAQ/googlemeet.png';
import Microsoft from 'image/FAQ/microsoft.png';
import Webex from 'image/FAQ/webex.png';
import Zoom from 'image/FAQ/zoom.png';
import Avaya from 'image/FAQ/avaya.png';
import Bluejeans from 'image/FAQ/bluejeans.png';
import Huawei from 'image/FAQ/huawei.png';
import StarLeaf from 'image/FAQ/starleaf.png';
import LifeSize from 'image/FAQ/lifesize.png';

export const topicsList = [
	'General',
	'Storing recordings',
	'Selfie Loop',
	'Recordings and conference providers',
	'Mobile',
	'Hyper-linked Notes',
	'Communication',
	'Encryption',
];

export const topicQues = {
	General: [11, 8, 19, 3],
	'Storing recordings': [2, 9, 13, 14],
	'Selfie Loop': [5, 10],
	'Recordings and conference providers': [1, 4, 14],
	Mobile: [7, 9, 10],
	'Hyper-linked Notes': [6, 17],
	Communication: [15, 18, 7],
	Encryption: [15, 16, 17, 7, 18],
};

export const faqs = {
	1: {
		tag: '1',
		question: 'How do I record a video on SEEYOU?',
		answer:
			'You can record anything on your screen with SEEYOU by pressing the recording button on the Toolbar. You then select which screen you wish to record. SEEYOU captures everything on this screen, until the recording is ended',
	},
	2: {
		tag: '2',
		question: 'Where is my recording stored?',
		answer:
			"SEEYOU by default stores your recordings in your computer's (macOS/Windows) /Documents folder. You can also choose to store your recordings on your personal YouTube Studio account. You can change where you wish the recordings to be stored within settings",
	},
	3: {
		tag: '3',
		question: 'Why is the real-time rewind function not available for me?',
		answer:
			'In order for real-time rewind to work, your PC needs to run Windows 8 or newer, as well as minimum 4GB Ram, and processors of the type Intel i3 gen 8, Intel i5 gen 4, AMD Ryzen 5, or newer',
	},
	4: {
		tag: '4',
		question: 'Which video conference providers work with SEEYOU?',
		AnswerComponent: () => {
			return (
				<>
					<PartnerP>
						SEEYOU works with all major conference providers, and more are
						coming
					</PartnerP>
					<PartnersDiv>
						<PartnersUl>
							<PartnersLi>
								<Image src={GoogleMeet} htmlWidth="105px" alt="GoogleMeet" />
							</PartnersLi>
							<PartnersLi>
								<Image src={Microsoft} htmlWidth="106px" alt="Microsoft" />
							</PartnersLi>
							<PartnersLi>
								<Image src={Webex} htmlWidth="87px" alt="Webex" />
							</PartnersLi>
							<PartnersLi>
								<Image src={Zoom} htmlWidth="79px" alt="Zoom" />
							</PartnersLi>
						</PartnersUl>
						<PartnersUl>
							<PartnersLi>
								<Image src={Avaya} htmlWidth="62px" alt="Avaya" />
							</PartnersLi>
							<PartnersLi>
								<Image src={Bluejeans} htmlWidth="78px" alt="BlueJeans" />
							</PartnersLi>
							<PartnersLi>
								<Image src={Huawei} htmlWidth="93px" alt="Huawei" />
							</PartnersLi>
							<PartnersLi>
								<Image src={StarLeaf} htmlWidth="84px" alt="StarLeaf" />
							</PartnersLi>
							<PartnersLi>
								<Image src={LifeSize} htmlWidth="87px" alt="LifeSize" />
							</PartnersLi>
						</PartnersUl>
					</PartnersDiv>
				</>
			);
		},
	},
	5: {
		tag: '5',
		question: 'How to enable the Selfie Loop?',
		AnswerComponent: () => {
			return (
				<>
					<PartnerP>
						In any video communication app, go to settings and choose
						camera/video
					</PartnerP>
					<ol
						style={{
							paddingLeft: '20px',
						}}
					>
						<li>Choose “SEEYOU Cam”</li>
						<li>You only need to do this once for each provider</li>
					</ol>
				</>
			);
		},
	},
	6: {
		tag: '6',
		question: 'How do the hyper-links on the sticky notes work?',
		answer:
			'You can take hyperlinked notes during any of your recordings. These notes are then linked to where in the recording you took the note. If you open a recording in the SEEYOU-library and click on any note taken during a recording you have stored, the video opens where you started taking the note. Kind of cool when preparing for an exam, a meeting, or whatever',
	},
	7: {
		tag: '7',
		question: 'Why must I download SEEYOU both on desktop and mobile?',
		answer:
			'To allow end-to-end encrypted communication such as SEEYOU chat, calls, and video calls, SEEYOU used your mobile phone to store the encryption key. This makes the communication completely private. SEEYOU cannot read the content nor see with whom you are communicating. Nor can anyone else. You can link your mobile device to your desktop device within SEEYOU to have the direct communication functions available on your desktop app too',
	},
	8: {
		tag: '8',
		question: 'How do I download SEEYOU?',
		AnswerComponent: () => {
			return (
				<>
					<PartnerP>
						You can download SEEYOU <Link href={`/downloads`}>here</Link>, or
						via your respective app store
					</PartnerP>
				</>
			);
		},
	},
	9: {
		tag: '9',
		question: 'Can I record when using SEEYOU on my mobile phone?',
		answer:
			'Limitations to the operating systems on iOS and Android prevents recordings on mobile phones. So, for now, this is a desktop feature only',
	},
	10: {
		tag: '10',
		question: 'Does the Selfie Loop™ work on my mobile phone?',
		answer:
			'No, the same problem as with recordings. So, for now, this is a desktop feature only',
	},
	11: {
		tag: '11',
		question: 'Is SEEYOU entirely free?',
		answer:
			'Yes, SEEYOU is entirely free to use, and there are no in-app purchases',
	},
	12: {
		tag: '12',
		category: 'General',
		question: 'Is storing recordings free?',
		answer:
			'Yes, storing your recordings is free. You can store either locally on your hard-drive, or on your YouTube Studio account',
	},
	13: {
		tag: '13',
		question: 'Is storing recordings safe?',
		answer:
			'Your conferences are stored on your computer or your personal YouTube Studios account, ensuring you benefit from all the safety features built into YouTube',
	},
	14: {
		tag: '14',
		question: 'Is recording and storing conferences legal?',
		answer:
			'SEEYOU is EU-based. According to GDPR, you may only record and store conferences for personal use. Please consult your applicable laws and regulations if recording and storing conferences outside of the EU/EEA',
	},
	15: {
		tag: '15',
		question: 'Are the chat and video messages end-to-end encrypted?',
		AnswerComponent: () => {
			return (
				<>
					<PartnerP>
						Yes, they are. SEEYOU's chat messages are end-to-end encrypted,
						meaning they're genuinely private. SEEYOU cannot read the content
						nor see with whom you are communicating. Nor can anyone else.
					</PartnerP>
					<PartnerP>
						Unlike many other providers, this holds for group chats too. The
						encryption key is personal and managed by your mobile phone, and
						logs are not centrally stored by SEEYOU but are yours alone
					</PartnerP>
				</>
			);
		},
	},
	16: {
		tag: '16',
		question: 'Are SEEYOU calls and video calls encrypted?',
		answer:
			'Calls, including video calls, made with SEEYOU are fully encrypted',
	},
	17: {
		tag: '17',
		question: 'Are the notes encrypted?',
		answer: 'Yes, our hyperlinked sticky notes are fully encrypted',
	},
	18: {
		tag: '18',
		question:
			"Can I use SEEYOU's encrypted communication outside of video conferences?",
		answer:
			'Yes, you can send fully encrypted chat messages or have fully encrypted calls with SEEYOU, even outside a video conference',
	},
	19: {
		tag: '19',
		question: "SEEYOU doesn't load properly. What should I do?",
		AnswerComponent: () => {
			return (
				<>
					<PartnerP>
						Sometimes this requires one of the following actions:
					</PartnerP>
					<ol
						style={{
							paddingLeft: '20px',
						}}
					>
						<li>Please check your internet connection</li>
						<li>Alternatively, try reloading SEEYOU</li>
						<li>
							If still not working, please go to the{' '}
							<Link href={`/downloads`}>downloads</Link> page and reinstall
							SEEYOU
						</li>
						<li>
							After reinstalling SEEYOU, we suggest also restarting your
							computer
						</li>
					</ol>
				</>
			);
		},
	},
	20: {
		tag: 'changelog',
		question: 'What is new in v2.0',
		AnswerComponent: () => {
			return (
				<>
					<PartnerP>
						SEEYOU v2.0 was released on February 28<sup>th</sup> 2023, meaning
						SEEYOU is no longer in beta. SEEYOU v2.0 has a complete new design
						and interface, making it even easier to record videos, access your
						calendars, book events, and rewatch recordings in your library,
						including those with hyperlinked notes. In addition, V2.0 comes with
						a set off new features, such as the option to start an instant
						meeting.
					</PartnerP>
					<PartnerP>
						We advise users who are still on the beta-app of SEEYOU to download
						the latest version. The app is improved and a number of bugs have
						been fixed. 
						Please download the latest version of SEEYOU{' '}
						<Link href={`/downloads`}>here</Link>.
					</PartnerP>
					{/* <PartnerP>
						For a look at what is different in V2.0, please have a look at the
						short video below.
					</PartnerP> */}
					{/* <br />
					<div
						style={{
							width: '100%',
							position: 'relative',
							paddingBottom: '56.25%',
						}}
					>
						<iframe
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								filter: 'drop-shadow(0px 9px 21px rgba(112, 88, 255, 0.15))',
							}}
							src="https://www.youtube.com/embed/6_eSywijtFc"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
					</div> */}
				</>
			);
		},
	},
};
