import { LLC } from './Address.styled';

const LLCComponent = ({ className, children }) => (
	<LLC className={className}>
		SEEYOU LTD, The CHQ Building, Custom House Quay, Dublin 1, D01 Y6H7 Ireland
		{children}
	</LLC>
);

export default LLCComponent;
