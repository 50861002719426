import {
	SectionTitle,
	OurDiscription,
	SectionTitleSub,
	Light,
	Box,
	GlobalSection,
} from './OurLeadership.styled';
import GlobalGoals from 'components/GlobalGoals';

import Container from '../Container';

const OurLeadership = () => {
	return (
		<GlobalSection>
			<Container>
				<Box>
					<SectionTitle>STRATEGY & VALUES</SectionTitle>
					<SectionTitleSub>We are a Force for Good</SectionTitleSub>
					<OurDiscription>
						<Light>PRODUCT</Light> Saving time and enabling what is otherwise
						almost impossible | Absolute product-market fit | Relentless product
						focus | Super simple | Hit problems with a simple stick |
						Self-explanatory UI/UX | Build something 100 people love, not
						something 1 million people like - people who really love a product
						will make it go viral | Measure everything, always | Make decisions
						| Continuous incremental improvement | Radically embrace change |
						Better to cannibalize own features than being eaten by others |
						Minimize operational and technical risk | Adhere to deadlines | Plan
						ahead | Build on our core culture | Get shit done
					</OurDiscription>
					<OurDiscription>
						<Light>PEOPLE</Light> 32 pax, 15 nationalities, 75% Engineers, 37.5%
						Women, 60% Female Management Group, 66% Female Founders. Two out of
						three founders hold PhDs | Hire selectively, outsource until we have
						the right people | No early offers less all three founders are
						thumbs up | Smart people only | Employ those having gone through a
						challenge and can articulate in detail how it was solved | Hire only
						those who love the product, and what we want to accomplish | If they
						can’t understand it without an explanation, they can’t understand it
						with an explanation | Three must-have competencies; talent, focus,
						and endurance | Hire for attitude, train for skills | The difference
						between 99% and 100% is more than 100% | Create actual user values |
						20%-time rule | Locate where talent is relatively most available |
						Be global | Respect all | Discriminate against no one | Bullies are
						out | No glue- people | No drama | No ego | No corporate suits | Be
						kind | Suaviter in modo, fortiter in re
					</OurDiscription>
					<OurDiscription>
						<Light>MARKET</Light> Do not compete, feed of the strength of others
						| Make the current players a tailwind, not a headwind | Free of
						charge, advertising revenues suffice | No early ads, they are not
						cool, the capital markets understand the value of daily user growth
						| SEEYOU is a double bottom line Social Impact Investment | Short
						term profit is secondary to long term goals | Do what we think is
						right, not necessarily what's popular | Save users' resources and
						simplify what otherwise would be hard to do | Create a global
						community | Non-scalable actions are ok to instigate scalable
						effects
					</OurDiscription>
					<OurDiscription>
						<Light>COMMUNICATION</Light> Full transparency | Never sugar-coat
						the truth; we're not Willy Wonka | No truth-shaving, same message to
						all | Never announce new functionality before launch | No
						fortune-telling | No forward-looking statements
					</OurDiscription>
					<GlobalGoals />
				</Box>
			</Container>
		</GlobalSection>
	);
};
export default OurLeadership;
