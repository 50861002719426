import Helmet from 'react-helmet';
import { Outlet } from 'react-router-dom';
// import { GlobalStyle } from '../GlobalStyle';
import AppBar from '../AppBar';
import Consent from './../Consent';

const Layout = () => {
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu" />
			</Helmet>
			<AppBar />
			<Outlet />
			<Consent />
		</>
	);
};

export default Layout;
