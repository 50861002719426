import Helmet from 'react-helmet';
import NotFound from 'components/NotFound';

const PageNotFound = () => {
	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
				<link rel="canonical" href="https://seeyou.eu/404" />
			</Helmet>
			<NotFound />
		</>
	);
};

export default PageNotFound;
