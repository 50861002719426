import styled from 'styled-components';

export const FileDropZone = styled.label`
	display: block;
	position: relative;
	font-family: ${({ theme }) => theme.fonts.body.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	font-size: 14px;
	line-height: 20px;
	padding: 8px 16px;
	margin-bottom: 32px;
	height: 106px;

	border: 1px dashed ${({ theme }) => theme.colors.prim_ten};
	background: ${({ theme }) => theme.colors.white};
	border-radius: 8px;
	&.invalid-input {
		border-color: ${({ theme }) => theme.colors.sec_eleven};
	}
	input {
		display: none;
	}
	button {
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: ${({ theme }) => theme.colors.primary};
	}
	button:hover {
		text-decoration-line: underline;
	}
`;

export const FileDropOverlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	top: 0;
	left: 0;
`;

export const Output = styled.div`
	display: flex;
	justify-content: around;
	align-items: start;
	border: 1px solid ${({ theme }) => theme.colors.prim_seven};
	border-radius: 8px;
	padding: 8px;
	margin-bottom: 8px;
	div.file-detail {
		flex-grow: 1;
		display: flex;
	}
	div.file-text-detail {
		font-family: ${({ theme }) => theme.fonts.descr.regular}
		font-weight: 400;
		font-size: 14px;
		font-height: 20px;
		padding-left: 8px;
		word-break: break-all;
		color: ${({ theme }) => theme.colors.prim_sixteen};
		& :last-child{
			color: ${({ theme }) => theme.colors.prim_twenty_one};
		}
	}
	
	button.remove {
		color: red;
		margin: 10px;
		text-decoration-line: none;
	}
`;
