import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import ContainerHeader from '../Container';
import Burger from '../Burger';

export const HeaderContainer = styled(ContainerHeader)`
	display: flex;
	width: 100%;
	align-items: center;
	padding: 0 20px;
	${({ theme }) => theme.device.tablet} {
		padding: 0 40px;
	}
	${({ theme }) => theme.device.default} {
		padding: 0 40px;
	}
	${({ theme }) => theme.device.desktop} {
		max-width: 1306px;
	}
`;

export const CustomBurger = styled(Burger)`
	order: 1;
	${({ theme }) => theme.device.tablet} {
		order: 5;
		margin-left: 16px;
	}
	${({ theme }) => theme.device.desktop} {
		display: none;
		order: 1;
	}
`;

export const HeaderBox = styled.div`
	order: 2;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ theme }) => theme.device.tablet} {
		justify-content: flex-start;
	}
	${({ theme }) => theme.device.desktop} {
		flex-grow: unset;
	}
`;

export const Nav = styled.nav`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-family: 'Lato_Regular';
	font-size: 18px;
	line-height: 1.5;
	order: 3;

	${({ theme }) => theme.device.defaultMax} {
		overflow-y: scroll;
		max-height: 90vh;
		padding: 32px;
		position: absolute;
		top: 100%;
		right: 0;
		left: 0;
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		transform: translateX(100%);
		background-color: ${({ theme }) => theme.colors.white};
		transition: transform 0.3s ease-out, opacity 0.3s ease-out,
			visibility 0.3s ease-out;
		&.active {
			visibility: visible;
			opacity: 1;
			transform: translateX(0);
			pointer-events: auto;
		}
	}
	${({ theme }) => theme.device.desktop} {
		flex-grow: 1;
		align-items: center;
		order: 3;
	}
`;

export const ButtonList = styled.ul`
	order: 4;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: flex-end;
	${({ theme }) => theme.device.tablet} {
		flex-direction: row;
		justify-content: flex-start;
		order: 4;
	}
	${({ theme }) => theme.device.desktop} {
		order: 4;
	}
`;

export const Header = styled.header`
	position: fixed;
	top: 0;
	padding-top: 12px;
	padding-bottom: 12px;
	margin: 0 auto;
	width: 100%;
	z-index: 1;
	background: white;
	box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
	${({ theme }) => theme.device.default} {
		padding-top: 25px;
		padding-bottom: 25px;
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const NavList = styled.ul`
	width: 100%;
	${({ theme }) => theme.device.desktop} {
		display: flex;
		align-items: center;
		width: auto;
	}
`;

export const Link = styled(NavLink)`
	${({ theme }) => theme.testFonts.mDesktop.header.bold};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	&.active {
		color: ${({ theme }) => theme.colors.primary};
		position: relative;
	}
`;
export const LinkDown = styled(NavLink)``;

export const LogoLink = styled(NavLink)`
	${({ theme }) => theme.device.mobileMax} {
		margin: 0 auto;
	}
`;

export const NavItem = styled.li`
	position: relative;
	${({ theme }) => theme.device.desktop} {
		&:not(:last-child) {
			margin-right: 48px;
		}
	}
	${({ theme }) => theme.device.defaultMax} {
		margin-left: 50px;
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
	${({ theme }) => theme.device.mobileMax} {
		margin-left: 0px;
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
`;

export const ButtonItem = styled.li`
	margin-right: 16px;
`;

export const LogoImg = styled.img`
	width: auto;
	height: 16.2px;
	${({ theme }) => theme.device.tablet} {
		width: auto;
		height: 19.5px;
	}
	${({ theme }) => theme.device.default} {
		width: auto;
		height: 19.5px;
	}
	${({ theme }) => theme.device.desktop} {
		width: auto;
		height: 30px;
	}
`;

export const NavLoginLink = styled.a`
	${({ theme }) => theme.device.defaultMax} {
		width: 144px;
		height: 40px;
	}
	${({ theme }) => theme.testFonts.mDesktop.header.bold};
	position: relative;
	margin: 0;
	color: ${({ theme }) => theme.colors.primary};
	background: ${({ theme }) => theme.colors.prim_twenty_two};
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 69px;
	height: 40px;
`;
