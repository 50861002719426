import React, { useState, createContext } from 'react';
import Cookies from 'js-cookie';

export const cookieTypes = [
	'session',
	'persistent',
	'necessary',
	'preferences',
	'statistics',
	'marketing',
	'firstParty',
	'thirdParty',
];
export const CookieContext = createContext();
export const CookieConsentProvider = ({ children }) => {
	let initialValue = Cookies.get('consent')
		? JSON.parse(Cookies.get('consent'))
		: {};
	const [consent, setConsent] = useState(initialValue);

	return (
		<CookieContext.Provider value={{ consent, setConsent }}>
			{children}
		</CookieContext.Provider>
	);
};
