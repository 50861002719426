import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const PopupMenu = styled.div`
	height: 420px;
	min-width: 170px;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	visibility: hidden;

	z-index: 2;
	padding: 16px 0;
	width: 100%;
	overflow: hidden;
	${({ theme }) => theme.device.mobileMax} {
		position: relative;
	}
	${({ theme }) => theme.device.desktop} {
		position: absolute;
		box-shadow: 0px 14px 32px rgba(112, 88, 255, 0.16);
		border-radius: 8px;
	}
	&.closed {
		height: 0;
		padding: 0;
	}
`;

export const Popup = styled.div`
	width: 100%;
	&.open ${PopupMenu} {
		visibility: visible;
	}
	${({ theme }) => theme.device.desktop} {
		width: auto;
	}
`;

export const Icon = styled.img`
	width: 16px;
	height: 9px;
	${({ theme }) => theme.device.desktop} {
		display: none;
	}
`;

export const PopupItem = styled.div`
	&:not(:last-child) {
		margin-bottom: 32px;
	}
`;

export const Link = styled(NavLink)`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	margin-left: 15px;
	display: block;
	&.active {
		color: ${({ theme }) => theme.colors.primary};
	}
	${({ theme }) => theme.device.tablet} {
		text-align: left;
	}
`;

export const PopupButton = styled.button`
	display: flex;
	justify-content: space-between;
	align-items: center;
	${({ theme }) => theme.testFonts.mDesktop.header.bold};
	&.active {
		color: ${({ theme }) => theme.colors.primary};
	}
	width: 100%;
	text-align: left;
	${({ theme }) => theme.device.desktop} {
		width: auto;
	}
`;
