import styled from 'styled-components';

export const GlobalSection = styled.section`
	margin-bottom: 90px;
	${({ theme }) => theme.device.tablet} {
	}
`;
export const ProImg = styled.img``;
export const ProTitle = styled.h1`
	${({ theme }) => theme.testFonts.mobile.h1};
	text-align: start;
	margin-bottom: 16px;
	margin-top: 0px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h1};
		text-align: center;
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h1};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h1};
	}
`;

export const ProDescription = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	text-align: start;
	margin: 0 auto 40px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	width: 329px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
		width: 531px;
		margin-bottom: 44px;
		text-align: center;
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
		width: 656px;
		text-align: center;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
		width: 840px;
		margin-bottom: 60px;
		text-align: center;
	}
`;

export const PartnersDiv = styled.div`
	text-align: center;
	padding-top: 0px;
	margin: 0 auto;
	width: 333px;
	${({ theme }) => theme.device.tablet} {
		width: 624px;
	}
	${({ theme }) => theme.device.default} {
		width: 758px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 945px;
	}
`;
export const PartnersUl = styled.ul`
	margin: 0 auto;
	display: flex;
	align-items: start;
	flex-wrap: wrap;
	justify-content: space-around;
	${({ theme }) => theme.device.tablet} {
		justify-content: space-around;
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
export const PartnersLi = styled.li`
	padding: 0;
	margin-bottom: 30px;
	width: 150px;

	${({ theme }) => theme.device.tablet} {
		width: auto;
		margin-left: 12px;
		margin-right: 12px;
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
export const Image = styled.img`
	width: auto;
	height: 28px;
	${({ theme }) => theme.device.tablet} {
		height: 25px;
	}
	${({ theme }) => theme.device.default} {
		height: 32px;
	}
	${({ theme }) => theme.device.desktop} {
		height: 40px;
	}
`;

export const Date = styled.p`
	text-align: center;
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	color: ${({ theme }) => theme.colors.prim_fifteen};
	${({ theme }) => theme.device.tablet} {
		text-align: right;
		margin-right: 2%;
	}
	${({ theme }) => theme.device.default} {
		margin-right: 8%;
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
