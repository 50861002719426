import { Container, InputWrapper, Label } from './RadioInput.css';

export const RadioInput = ({ name, formik, values, styles }) => {
	const { inputStyle, labelStyle, containerStyle } = styles || {};

	return (
		<>
			{values.map((data, index) => {
				const { value, content } = data;
				return (
					<Container styles={containerStyle} key={index}>
						<InputWrapper>
							<input
								id={index}
								styles={inputStyle}
								type={'radio'}
								name={name}
								value={value}
								checked={formik.values[name] === value}
								onBlur={formik.handleBlur}
								onChange={() => formik.setFieldValue(name, value)}
							/>
						</InputWrapper>
						<Label styles={labelStyle} htmlFor={index}>
							{content}
						</Label>
					</Container>
				);
			})}
		</>
	);
};
