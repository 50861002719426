import { useState } from 'react';
import Helmet from 'react-helmet';
import ReportForm from 'components/Report';
import ReportFaq from 'components/ReportFaq';
import Address from 'components/Address';
import Main from 'components/Main';

import {
	Container,
	Section,
	FormWrapper,
	FaqWrapper,
	FaqTitle,
	mainBackgroundStyle,
} from './Report.styled';
import { ScrollToTop } from 'hooks/ScrollToTop';

const Report = () => {
	const [topic, updateTopic] = useState('');
	ScrollToTop();
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu/problem" />
			</Helmet>
			<Main style={{ ...mainBackgroundStyle }}>
				<Container>
					<Section>
						<FormWrapper>
							<ReportForm updateTopic={updateTopic} />
						</FormWrapper>
						<FaqWrapper>
							<FaqTitle>Related questions</FaqTitle>
							<ReportFaq topic={topic} />
						</FaqWrapper>
					</Section>
				</Container>
			</Main>
			<Address />
		</>
	);
};
export default Report;
