import styled from 'styled-components';

export const ModalInput = styled.input`
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	background: ${({ theme }) => theme.colors.prim_three};
	border: 1px solid ${({ theme }) => theme.colors.prim_seven};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	font-size: 14px;
	padding: 8px 16px;
	border-radius: 8px;
	width: 100%;
	&.invalid-input {
		border: 1px solid ${({ theme }) => theme.colors.sec_eleven};
	}
`;

export const ModalText = styled.textarea`
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	background: ${({ theme }) => theme.colors.prim_three};
	border: 1px solid ${({ theme }) => theme.colors.prim_seven};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	font-size: 14px;
	width: 100%;
	padding: 8px 16px;
	border-radius: 8px;
	&.invalid-input {
		border: 1px solid ${({ theme }) => theme.colors.sec_eleven};
	}
`;

export const ModaButtonSend = styled.button`
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	background: ${({ theme }) => theme.colors.prim_ten};
	color: ${({ theme }) => theme.colors.prim_three};
	width: 155px;
	padding: 10px 0px;
	border-radius: 4px;
	margin: auto;
	:disabled {
		background: ${({ theme }) => theme.colors.grey_off};
		cursor: not-allowed;
	}
`;

export const Form = styled.form`
	box-shadow: 0px 10.9453px 24.0796px rgba(112, 88, 255, 0.16);
	padding: 34px 12px 32px 14px;
	border-radius: 15px;
	background: ${({ theme }) => theme.colors.white};
	${({ theme }) => theme.device.tablet} {
		padding: 34px 36px 32px 32px;
	}
`;

export const FormTitle = styled.h1`
	${({ theme }) => theme.testFonts.mobile.h1};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	text-align: center;
	margin-bottom: 24px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h1};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h1};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h1};
	}
`;

export const FormSubTitle = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	text-align: center;
	margin-bottom: 39px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
		margin-bottom: 46px;
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
	}
`;

export const InputWrapper = styled.div`
	margin-bottom: 24px;
`;

export const InputName = styled.p`
	${({ theme }) => theme.testFonts.desktop.dropDown};
	color: ${({ theme }) => theme.colors.prim_seventeen};
	margin-bottom: 12px;
`;
export const ErrorMessage = styled.p`
	line-height: 12px;
	margin: 8px auto;
	font-size: 10px;
	border-radius: 5px;
	color: ${({ theme }) => theme.colors.red};
`;

export const SuccessMessage = styled.p`
	${({ theme }) => theme.testFonts.desktop.button};
	font-weight: 400;
	text-align: center;
	padding-top: 24px;
	color: ${({ theme }) => theme.colors.green};
`;
