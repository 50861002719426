import ReactSelect from 'react-select';
import styled from 'styled-components';
import { theme } from 'constants/theme';

export const Image = styled.img``;
export const ModalSelect = styled(ReactSelect)``;

export const customSelectStyles = {
	container: (defaultStyles, state) => ({
		...defaultStyles,
		borderRadius: '8px',
		width: '100%',
		border: `1px solid ${theme.colors.prim_seven}`,
		background: `${theme.colors.prim_three}`,
		marginBottom: '12px',
		padding: '0px 16px',
	}),
	indicatorsContainer: (defaultStyles, state) => ({
		...defaultStyles,
		marginLeft: '10px',
	}),
	valueContainer: (defaultStyles) => ({
		...defaultStyles,
		padding: 0,
		margin: 0,
	}),
	input: (defaultStyles) => ({
		...defaultStyles,
		fontFamily: theme.fonts.descr.regular,
		color: theme.colors.prim_sixteen,
		fontSize: '14px',
		padding: 0,
		margin: 0,
	}),
	control: (defaultStyles) => ({
		...defaultStyles,
		border: 'none',
		boxShadow: 'none',
		padding: 0,
		margin: 0,
	}),
	singleValue: (defaultStyles) => ({
		...defaultStyles,
		fontFamily: theme.fonts.descr.regular,
		color: theme.colors.prim_sixteen,
		fontSize: '14px',
		padding: 0,
		margin: 0,
	}),
	// selectValue: (defaultStyles) => ({
	// 	...defaultStyles,
	// }),
	menu: (defaultStyles) => ({
		...defaultStyles,
		left: 0,
	}),
	option: (defaultStyles, state) => ({
		...defaultStyles,
		fontFamily: theme.fonts.descr.regular,
		color: theme.colors.prim_sixteen,
		fontSize: '14px',
		lineHeight: '19px',
		background: 'none',
		':hover': {
			color: theme.colors.white,
			background: theme.colors.prim_sixteen,
		},
	}),
};
