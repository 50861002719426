import {
	HeroSection,
	HeroTitle,
	HeroDiscription,
	HeroContainer,
	HeroThumb,
	HeroImg,
	GlobalBox,
	// HeroSectionTitleSub,
	// DisplayPointer,
} from './Hero.styled';
import DesktopDownloadLinks from 'components/DesktopDownloadLinks';
// import { scrollTo } from 'hooks/ScrollToTop';

import HeroIllustration from 'image/hero/main-image.png';
import MediaQuery from 'components/MediaQuery';

const Hero = () => {
	return (
		<HeroSection>
			<HeroContainer>
				<HeroThumb>
					<HeroImg src={HeroIllustration} alt="hero illustration" />
				</HeroThumb>
				<GlobalBox>
					<HeroTitle>
						100% Free
						<br />
						Recordings
					</HeroTitle>
					{/* <HeroSectionTitleSub>
						Encompassing your digital life
					</HeroSectionTitleSub> */}
					<HeroDiscription>
						Universal Screen & Sound Capture
						<br />
						No Watermarks or Time Limits
					</HeroDiscription>
					<MediaQuery device={'tablet'}>
						<DesktopDownloadLinks />
					</MediaQuery>
					<MediaQuery device={'default'}>
						<DesktopDownloadLinks />
					</MediaQuery>
				</GlobalBox>
			</HeroContainer>
		</HeroSection>
	);
};

export default Hero;
