import { GlobalGoalsUl, GlobalGoalsLi, Image } from './GlobalGoals.styled';
import QualityEducation from '../../image/GlobalGoals/4-quality-education.png';
import GenderEquality from '../../image/GlobalGoals/5-gender-equality.png';
import DecentWorkAndEconomicGrowth from '../../image/GlobalGoals/8-decent-work-and-economic-growth.png';
import IndustryInnovationAndInfrastructure from '../../image/GlobalGoals/9-industry-innovation-and-infrastructure.png';
import ReducedInequalities from '../../image/GlobalGoals/10-reduced-inequalities.png';
import SustainableCitiesAndCommunities from '../../image/GlobalGoals/11-sustainable-cities-and-communities.png';
import ResponsibleConsumptionAndProduction from '../../image/GlobalGoals/12-responsible-consumption-and-production.png';
import ClimateAction from '../../image/GlobalGoals/13-climate-action.png';

const GlobalGoals = () => {
	return (
		<div>
			<GlobalGoalsUl>
				<GlobalGoalsLi>
					<a
						href="https://www.globalgoals.org/goals/4-quality-education/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<Image src={QualityEducation} alt="4QualityEducation" />
					</a>
				</GlobalGoalsLi>
				<GlobalGoalsLi>
					<a
						href="https://www.globalgoals.org/goals/5-gender-equality/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<Image src={GenderEquality} alt="5GenderEquality" />
					</a>
				</GlobalGoalsLi>
				<GlobalGoalsLi>
					<a
						href="https://www.globalgoals.org/goals/8-decent-work-and-economic-growth/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<Image
							src={DecentWorkAndEconomicGrowth}
							alt="8DecentWorkAndEconomicGrowth"
						/>
					</a>
				</GlobalGoalsLi>
				<GlobalGoalsLi>
					<a
						href="https://www.globalgoals.org/goals/9-industry-innovation-and-infrastructure/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<Image
							src={IndustryInnovationAndInfrastructure}
							alt="9IndustryInnovationAndInfrastructure"
						/>
					</a>
				</GlobalGoalsLi>
				<GlobalGoalsLi>
					<a
						href="https://www.globalgoals.org/goals/10-reduced-inequalities/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<Image src={ReducedInequalities} alt="10ReducedInequalities" />
					</a>
				</GlobalGoalsLi>
				<GlobalGoalsLi>
					<a
						href="https://www.globalgoals.org/goals/11-sustainable-cities-and-communities/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<Image
							src={SustainableCitiesAndCommunities}
							alt="11SustainableCitiesAndCommunities"
						/>
					</a>
				</GlobalGoalsLi>
				<GlobalGoalsLi>
					<a
						href="https://www.globalgoals.org/goals/12-responsible-consumption-and-production/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<Image
							src={ResponsibleConsumptionAndProduction}
							alt="12ResponsibleConsumptionAndProduction"
						/>
					</a>
				</GlobalGoalsLi>
				<GlobalGoalsLi>
					<a
						href="https://www.globalgoals.org/goals/13-climate-action/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<Image src={ClimateAction} alt="13ClimateAction" />
					</a>
				</GlobalGoalsLi>
			</GlobalGoalsUl>
		</div>
	);
};

export default GlobalGoals;
