import styled from 'styled-components';

export const GlobalSection = styled.section`
	padding-bottom: 44px;
	${({ theme }) => theme.device.tablet} {
		padding-bottom: 65px;
	}
	${({ theme }) => theme.device.default} {
		padding-bottom: 88px;
	}
	${({ theme }) => theme.device.desktop} {
		padding-bottom: 94px;
	}
`;
export const Box = styled.div`
	padding-right: 8px;
	padding-left: 8px;
	${({ theme }) => theme.device.tablet} {
		padding-right: 0px;
		padding-left: 0px;
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
export const SectionTitle = styled.h1`
	${({ theme }) => theme.testFonts.mobile.h1};
	text-align: center;
	margin-top: 0;
	margin-bottom: 8px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h1};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h1};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h1};
	}
`;
export const SectionTitleSub = styled.h2`
	${({ theme }) => theme.testFonts.mobile.h2};
	text-align: center;
	margin-bottom: 45px;
	color: ${({ theme }) => theme.colors.orange};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h2};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h2};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h2};
	}
`;

export const OurDiscription = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	&:not(:last-child) {
		margin-bottom: 20px;
	}
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
		&:not(:last-child) {
			margin-bottom: 40px;
		}
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
		&:not(:last-child) {
			margin-bottom: 40px;
		}
	}
`;

export const Light = styled.span`
	${({ theme }) => theme.testFonts.mobile.body.bold};
	color: ${({ theme }) => theme.colors.orange};
	margin-bottom: 20px;
	margin-right: 4px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.bold};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.bold};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.bold};
	}
`;
