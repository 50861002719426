import {
	ConferenceTitle,
	ConferenceDiscription,
	SectionTitle,
	SectionTitleSub,
	ConferenceItem,
	ConferenceDiscriptionSub,
	AboutUl,
	ContactLink,
	Image,
	DivImg,
} from './AboutHero.styled';
import GlobalSection from '../Section';
import Container from '../Container';

import GlobalImage from '../GlobalImage';
import GlobalBox from '../GlobalBox';
import Founders from 'image/about/founders.png';
import CoFounders from 'image/about/team.png';
import Share from 'image/about/shareholders.png';
import Location from 'image/about/location_v2.png';

const AboutHero = () => {
	return (
		<GlobalSection>
			<Container>
				<SectionTitle>HISTORY and COMMUNITY</SectionTitle>
				<SectionTitleSub>We are a Force for Good</SectionTitleSub>
				<AboutUl>
					<ConferenceItem>
						<GlobalBox>
							<ConferenceTitle>Founders</ConferenceTitle>

							<ConferenceDiscription>
								SEEYOU was first envisioned in September 2020 by{' '}
								<ContactLink
									href="https://www.linkedin.com/in/bjorn-selleg-914b355a/"
									target={'_blank'}
								>
									Bjorn Selleg
								</ContactLink>{' '}
								as a double-bottom-line social impact project. Soon after,{' '}
								<ContactLink
									href="https://www.linkedin.com/in/lise-fl%C3%B8vik-phd-33bbb742/"
									target={'_blank'}
								>
									Dr. Lise Flovik
								</ContactLink>{' '}
								and{' '}
								<ContactLink
									href=" https://www.linkedin.com/in/jolien-vleeshouwers-phd-bb114465/"
									target={'_blank'}
								>
									Dr. Jolien Vleeshouwers
								</ContactLink>{' '}
								got involved as the two other founders
							</ConferenceDiscription>
							<ConferenceDiscription>
								The team used ten months to scope out the first-ever video
								conference aggregator and end-to-end encrypted communication
								platform. Moving to Dublin, embarking on another 16-month
								development cycle, our now 32 pax team of 15 nationalities
								evolved SEEYOU from the world's first video conference
								aggregator to a world-first intelligent toolbar and library
								encompassing your entire digital life<sup>*</sup>
							</ConferenceDiscription>
							<ConferenceDiscriptionSub>
								<sup>*</sup>And, by September 1st, 2023, we will also add the
								ability to record, store and make hyperlinked notes to
								in-real-life/non-digital meetings, presentations and lectures.
								This will give SEEYOU users full permanent intelligent ownership
								of any online or offline event they attend
							</ConferenceDiscriptionSub>
						</GlobalBox>
						<GlobalImage>
							<Image src={Founders} alt="founders" />
						</GlobalImage>
					</ConferenceItem>
					<ConferenceItem>
						<GlobalBox>
							<ConferenceTitle>Co-Founders and Team</ConferenceTitle>
							<ConferenceDiscription>
								In the summer and fall of 2021,{' '}
								<ContactLink
									href="https://www.linkedin.com/in/sunderkimatrai/"
									target={'_blank'}
								>
									Sunder Kimatrai
								</ContactLink>
								,{' '}
								<ContactLink
									href="https://www.linkedin.com/in/twana-daniel/"
									target={'_blank'}
								>
									Twana Daniel
								</ContactLink>
								, and{' '}
								<ContactLink
									href="https://www.linkedin.com/in/helendomina"
									target={'_blank'}
								>
									Helen Domina,
								</ContactLink>{' '}
								and{' '}
								<ContactLink
									href="https://www.linkedin.com/in/olgalevytska/"
									target={'_blank'}
								>
									Olga Levytska
								</ContactLink>{' '}
								joined as co-founders.
							</ConferenceDiscription>
							<ConferenceDiscription>
								By now, we’re a 32 pax team of 15 nationalities, all
								shareholders with only one native English speaker among us,
								backed by deep-pocketed Nordic impact investors, on a mission to
								be a force for good
							</ConferenceDiscription>
						</GlobalBox>
						<GlobalImage>
							<Image src={CoFounders} alt="co-founders" />
						</GlobalImage>
					</ConferenceItem>
					<ConferenceItem>
						<GlobalBox>
							<ConferenceTitle>Shareholders and Community</ConferenceTitle>
							<ConferenceDiscription>
								Our shareholder community includes another five PhD's and four
								more psychologists, over ten successful entrepreneurs, a group
								of former and current CEOs, legal, IT, communication, and
								industry professionals, and the SEEYOU team. We are funded by
								long-term private capital only, all approaching this as a double
								bottom line social impact investment
							</ConferenceDiscription>
							{/* <ConferenceDiscription>
								SEEYOU will be partly user-owned. An equivalent of 5% of the
								original 2.5 million shares will be gifted to active* users and
								recruiters during the first 25 months from September 1st 2023
							</ConferenceDiscription>
							<ConferenceDiscriptionSub>
								(<sup>*</sup>Final terms to be announced)
							</ConferenceDiscriptionSub> */}
						</GlobalBox>
						<GlobalImage>
							<Image src={Share} alt="Founders" />
						</GlobalImage>
					</ConferenceItem>
					<ConferenceItem>
						<GlobalBox>
							<ConferenceTitle>Locations</ConferenceTitle>
							<ConferenceDiscription>
								SEEYOU is an Irish company headquartered in Dublin, with
								subsidiaries in Dubai and Kyiv [temporarily all of the Ukrainian
								team and their families are safely evacuated to Ireland], and
								representation in Oslo and Sydney
							</ConferenceDiscription>
						</GlobalBox>
						<GlobalImage>
							<DivImg>
								<Image src={Location} alt="Founders" />
							</DivImg>
						</GlobalImage>
					</ConferenceItem>
				</AboutUl>
			</Container>
		</GlobalSection>
	);
};
export default AboutHero;
