import { useState, useEffect } from 'react';
import {
	ChakraProvider,
	Box,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
} from '@chakra-ui/react';
import { MinusIcon, AddIcon } from '@chakra-ui/icons';
import { Questions, Answer, ShowMore } from './FaqSection.styled';
import { theme } from 'constants/theme';
import { topicQues, faqs } from 'data/faq';

const CustomAccordion = (props) => {
	const { question, answer, AnswerComponent, style = {} } = props;
	if (question && (answer || AnswerComponent))
		return (
			<Accordion allowMultiple paddingBottom="16px" style={{ ...style }}>
				<AccordionItem
					border={'none'}
					borderRadius="12px"
					backgroundColor="#ECF1FE"
				>
					{({ isExpanded }) => (
						<>
							<h2>
								<AccordionButton
									_hover={{
										backgroundColor: 'transparent',
										boxShadow: 'none',
									}}
									_focus={{
										boxShadow: 'none',
									}}
									paddingRight="40px"
								>
									<Box flex="1" textAlign="left">
										<Questions>{question}</Questions>
									</Box>
									{isExpanded ? (
										<MinusIcon
											fontSize="12px"
											color={theme.colors.prim_fifteen}
										/>
									) : (
										<AddIcon
											fontSize="12px"
											color={theme.colors.prim_fifteen}
										/>
									)}
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								<Answer>
									{answer && <p>{answer}</p>}
									{AnswerComponent && <AnswerComponent />}
								</Answer>
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			</Accordion>
		);
};

const FaqSection = (props) => {
	const { topic } = props;
	const [prevTopic, updatePrevTopic] = useState('');
	const pageSize = 3;
	const [faqList, updateFaqList] = useState([]);
	const [page, updatePage] = useState(1);
	const [total, updateTotal] = useState(0);

	const fetchData = (page, pageSize) => {
		const quesIds = topicQues[topic];
		if (!topic) {
			return [];
		}
		const resutl = quesIds.reduce((prev, currentId) => {
			const item = faqs[currentId];
			if (item.question && (item.answer || item.AnswerComponent)) {
				return [
					...prev,
					{
						...item,
					},
				];
			} else {
				return [...prev];
			}
		}, []);

		const data = resutl.filter((prev, index) => !(index + 1 > page * pageSize));
		return data;
	};

	// Reset fields on topic change
	useEffect(() => {
		if (!topic) {
			return;
		}
		if (topic !== prevTopic) {
			updatePrevTopic(() => {
				updatePage(1);
				updateFaqList([]);
				updateTotal(0);
				return topic;
			});
		}
	}, [topic]);

	// Set total question count on receiving topic
	useEffect(() => {
		if (!prevTopic) {
			return;
		}
		const totalQuestion = fetchData(1, 1000);
		updateTotal(() => {
			updatePage(1);
			return totalQuestion.length;
		});
	}, [prevTopic]);

	// Get initially data on receiving total count
	useEffect(() => {
		if (total) {
			const data = fetchData(page, pageSize);
			updateFaqList([...data]);
		}
	}, [total]);

	// Get data on page update
	useEffect(() => {
		if (page) {
			const data = fetchData(page, pageSize);
			updateFaqList([...data]);
		}
	}, [page]);

	const handleMoreButton = () => {
		updatePage((prev) => prev + 1);
	};

	return (
		<>
			<ChakraProvider>
				<Box>
					{!!topic &&
						faqList.map(({ answer, question, AnswerComponent }, index) => (
							<CustomAccordion
								key={index}
								answer={answer}
								question={question}
								AnswerComponent={AnswerComponent}
							/>
						))}
					{!!total && total > page * pageSize && (
						<ShowMore onClick={handleMoreButton}>More questions</ShowMore>
					)}
				</Box>
			</ChakraProvider>
		</>
	);
};

export default FaqSection;
