import { useState, useEffect } from 'react';
import { RadioInput } from 'components/RadioInput';
import {
	InputWrapper,
	ModalInput,
	ModaButtonSend,
	Form,
	FormTitle,
	FormSubTitle,
	InputName,
	ModalText,
	ErrorMessage,
	SuccessMessage,
} from './UninstallerForm.styled';

import axios from 'axios';

import { useFormValidator } from 'hooks/FormValidator';
import uninstallerTopic from 'data/uninstallerTopic';

const SuggestForm = () => {
	let initialValues = {
		topic: uninstallerTopic[0].value,
		email: '',
		description: '',
	};

	const [successMessage, setSuccessMessage] = useState(false);

	const onSubmit = async (values, helper) => {
		try {
			const { topic, email, description } = values;
			const data = {
				subject: 'Account deleted',
				email: email.replace(/\s\s+/g, ' ').trim(),
				description: description.replace(/\s\s+/g, ' ').trim(),
				topic: topic.replace(/\s\s+/g, ' ').trim(),
			};

			await axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/public/feedback`,
				data,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			helper.resetForm();
			setTimeout(() => setSuccessMessage(true), 500);
		} catch (e) {
			console.error(e);
		}
	};
	let formik = useFormValidator(initialValues, onSubmit);

	useEffect(() => {
		setSuccessMessage(false);
	}, [formik.values]);

	return (
		<>
			<FormTitle>Sorry to see you go</FormTitle>
			<FormSubTitle>Please tell us how to improve</FormSubTitle>
			<Form onSubmit={formik.handleSubmit}>
				<InputWrapper>
					<RadioInput
						name={'topic'}
						formik={formik}
						values={uninstallerTopic}
					/>
					{formik.errors.topic && formik.touched.topic && (
						<ErrorMessage>
							<span>{formik.errors.topic}</span>
						</ErrorMessage>
					)}
				</InputWrapper>
				<InputWrapper>
					<InputName>Email Address</InputName>
					<ModalInput
						type="text"
						name="email"
						value={formik.values.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className={
							formik.errors.email && formik.touched.email ? 'invalid-input' : ''
						}
					/>
					{formik.errors.email && formik.touched.email && (
						<ErrorMessage>
							<span>{formik.errors.email}</span>
						</ErrorMessage>
					)}
				</InputWrapper>

				<InputWrapper>
					<InputName>Further feedback</InputName>
					<ModalText
						rows="3"
						type="text"
						name="description"
						value={formik.values.description}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className={
							formik.errors.description && formik.touched.description
								? 'invalid-input'
								: ''
						}
					/>
					{formik.errors.description && formik.touched.description && (
						<ErrorMessage>
							<span>{formik.errors.description}</span>
						</ErrorMessage>
					)}
				</InputWrapper>

				<div style={{ display: 'flex' }}>
					<ModaButtonSend type="submit">Submit</ModaButtonSend>
				</div>
			</Form>
			{successMessage && <SuccessMessage>Request sent</SuccessMessage>}
		</>
	);
};
export default SuggestForm;
