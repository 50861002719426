import { useState, useEffect } from 'react';
import {
	InputWrapper,
	ModalInput,
	ModaButtonSend,
	Form,
	FormTitle,
	InputName,
	ModalText,
	ErrorMessage,
	SuccessMessage,
} from './SuggestForm.styled';
import axios from 'axios';

import { useFormValidator } from 'hooks/FormValidator';

const SuggestForm = () => {
	let initialValues = {
		subject: 'Suggest a feature',
		email: '',
		description: '',
	};
	const [disableSubmit, updateSubmitState] = useState(0);
	const [successMessage, setSuccessMessage] = useState(false);

	const onSubmit = async (values, helper) => {
		try {
			const { subject, email, description } = values;
			const data = {
				subject: subject.replace(/\s\s+/g, ' ').trim(),
				email: email.replace(/\s\s+/g, ' ').trim(),
				description: description.replace(/\s\s+/g, ' ').trim(),
				topic: 'Suggest a feature',
			};

			await axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/public/feedback`,
				data,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			helper.resetForm();
			setTimeout(() => setSuccessMessage(true), 500);
		} catch (e) {
			console.log(e);
		}
	};
	let formik = useFormValidator(initialValues, onSubmit);

	useEffect(() => {
		setSuccessMessage(false);
	}, [formik.values]);

	useEffect(() => {
		updateSubmitState(!!Object.keys(formik.errors).length);
	}, [formik.errors]);

	return (
		<>
			<FormTitle>Suggest a Feature</FormTitle>
			<Form onSubmit={formik.handleSubmit}>
				<InputWrapper>
					<InputName>Email Address</InputName>
					<ModalInput
						type="text"
						name="email"
						value={formik.values.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className={
							formik.errors.email && formik.touched.email ? 'invalid-input' : ''
						}
					/>
					{formik.errors.email && formik.touched.email && (
						<ErrorMessage>
							<span>{formik.errors.email}</span>
						</ErrorMessage>
					)}
				</InputWrapper>

				<InputWrapper>
					<InputName>Description</InputName>
					<ModalText
						rows="3"
						type="text"
						name="description"
						value={formik.values.description}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className={
							formik.errors.description && formik.touched.description
								? 'invalid-input'
								: ''
						}
					/>
					{formik.errors.description && formik.touched.description && (
						<ErrorMessage>
							<span>{formik.errors.description}</span>
						</ErrorMessage>
					)}
				</InputWrapper>

				<div style={{ display: 'flex' }}>
					<ModaButtonSend type="submit" disabled={disableSubmit}>
						Send
					</ModaButtonSend>

					{successMessage && <SuccessMessage>Request sent</SuccessMessage>}
				</div>
			</Form>
		</>
	);
};
export default SuggestForm;
