import { useState, useEffect, useRef } from 'react';
import DownArrow from 'image/icons/down.svg';
import { Image, ModalSelect, customSelectStyles } from './Select.styled';

const CustomSelect = (props) => {
	const selectRef = useRef(null);

	const { name, defaultValue, options, handler: formik, reset } = props;

	const [currentValue, updateCurrentValue] = useState(defaultValue);

	const onSelectChange = (option, { action, prevInputValue }) => {
		const value = option ? option.value : '';
		updateCurrentValue(option);
		formik.setFieldValue(name, value);
	};

	useEffect(() => {
		if (reset) {
			selectRef.current.clearValue();
			selectRef.current.setValue(currentValue, 'select-option', undefined);
		}
	}, [reset]);
	return (
		<ModalSelect
			ref={selectRef}
			defaultValue={defaultValue}
			name={name}
			options={options}
			isSearchable={false}
			onChange={onSelectChange}
			components={{
				DropdownIndicator: () => (
					<div>
						<Image src={DownArrow} htmlWidth="11px" alt={`Open ${name} menu`} />
					</div>
				),
				IndicatorSeparator: null,
			}}
			styles={customSelectStyles}
		/>
	);
};
export default CustomSelect;
