import Helmet from 'react-helmet';
import AboutHero from 'components/AboutHero';
import Address from 'components/Address';
import { ScrollToTop } from 'hooks/ScrollToTop';
import OurLeadership from 'components/OurLeadership';
const About = () => {
	ScrollToTop();
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu/about" />
			</Helmet>
			<AboutHero />
			<OurLeadership />
			<Address />
			{/* <MediaQuery device={'mobile'}>
        <DownloadMobileFooter />
      </MediaQuery> */}
		</>
	);
};

export default About;
