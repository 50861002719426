import {
	Title,
	Discription,
	Link,
	Section,
	GlobalBox,
	ButDiv,
} from '../More/More.styled';
import Container from '../Container/Container.styled';
import DeletePDF from '../../PDF/delete_account_v2.pdf';

const DeleteHero = () => {
	return (
		<>
			<Section>
				<Container>
					<GlobalBox>
						<Title>Delete Account</Title>
						<Discription>
							To delete your SEEYOU account fully, click on the Delete SEEYOU
							guide below.
						</Discription>
						<ButDiv>
							<Link href={DeletePDF} target="_blank" rel="nofollow">
								Delete Account Guide
							</Link>
						</ButDiv>
					</GlobalBox>
				</Container>
			</Section>
		</>
	);
};
export default DeleteHero;
