import Helmet from 'react-helmet';
import UninstallerForm from 'components/Uninstaller/UninstallerForm';
import { Container, Section, FormWrapper } from './Uninstaller.styled';
import Address from 'components/Address';
import Main from 'components/Main';
import { ScrollToTop } from 'hooks/ScrollToTop';

import top from 'image/bg/top_form_page.png';

const Uninstaller = () => {
	ScrollToTop();
	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
				<link rel="canonical" href="https://seeyou.eu/uninstall" />
			</Helmet>
			<Main
				style={{
					background: `top left / 100% 750px no-repeat url(${top})`,
				}}
			>
				<Container>
					<Section>
						<FormWrapper>
							<UninstallerForm />
						</FormWrapper>
					</Section>
				</Container>
			</Main>
			<Address />
		</>
	);
};
export default Uninstaller;
