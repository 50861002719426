import styled from 'styled-components';
import Container from '../../Container';

export const Section = styled.section`
	padding-top: 76px;
	padding-bottom: 80px;
	${({ theme }) => theme.device.tablet} {
		padding-top: 120px;
		padding-bottom: 120px;
	}
	${({ theme }) => theme.device.default} {
		padding-top: 183px;
		padding-bottom: 143px;
	}
	${({ theme }) => theme.device.desktop} {
		padding-top: 162px;
		padding-bottom: 180px;
	}
`;

export const Title = styled.h1`
	font-family: ${({ theme }) => theme.fonts.title.semiBold};
	font-weight: 600;
	font-size: 30px;
	line-height: 43px;
	margin-bottom: 16px;
	text-align: left;
	width: 100%;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		width: 309px;
		font-size: 26px;
		line-height: 38px;
	}
	${({ theme }) => theme.device.default} {
		width: 335px;
		font-size: 29.2px;
		line-height: 42px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 100%;
		margin-top: 30px;
		font-size: 44px;
		line-height: 55px;
	}
`;

export const DownloadLink = styled.a`
	position: relative;
	display: flex;
	align-items: center;
	width: 290px;
	height: 48px;
	font-family: ${({ theme }) => theme.fonts.descr.semiBold};
	padding: 14px;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	margin: auto;
	font-size: 16px;
	line-height: 20px;
	background: ${({ theme }) => theme.colors.primary};
	cursor: pointer;
	padding-left: 25px;
	${({ theme }) => theme.device.tablet} {
		margin: 0px;
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const DownloadButton = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	width: 290px;
	height: 48px;
	font-family: ${({ theme }) => theme.fonts.descr.semiBold};
	padding: 14px;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	margin: auto;
	font-size: 16px;
	line-height: 20px;
	background: ${({ theme }) => theme.colors.primary};
	cursor: pointer;
	padding-left: 25px;
	${({ theme }) => theme.device.tablet} {
		margin: 0px;
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const PopupBox = styled.div`
	background: #fff;
	box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	display: none;
	flex-direction: column;
	justify-content: space-around;
	padding: 15px;
	position: absolute;
	z-index: 2;
	top: 100%;
	width: 100%;
	left: 0;
	${DownloadButton}:hover & {
		display: flex;
	}
	&:hover {
		display: flex;
	}
	${({ theme }) => theme.device.tablet} {
	}
`;

export const PopLink = styled.a`
	position: relative;
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	font-size: 14px;
	line-height: 19px;
	margin-bottom: 15px;
	text-align: start;
	width: 100%;
	&.active {
		font-family: ${({ theme }) => theme.fonts.descr.bold};
	}
	&:last-child {
		margin-bottom: 0;
	}
	${({ theme }) => theme.device.tablet} {
		font-size: 14px;
	}
`;

export const DownloadMobileTitle = styled.h2`
	font-family: ${({ theme }) => theme.fonts.title.semiBold};
	font-size: 30px;
	line-height: 43.4px;
	margin-bottom: 16px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	text-align: left;
	${({ theme }) => theme.device.tablet} {
		font-size: 26px;
		line-height: 37.6px;
	}
	${({ theme }) => theme.device.default} {
		font-size: 29.2px;
		line-height: 42.2px;
	}
	${({ theme }) => theme.device.desktop} {
		font-size: 45px;
		line-height: 55px;
	}
`;

export const HeroThumb = styled.div`
	margin-bottom: 16px;
	${({ theme }) => theme.device.tablet} {
		width: 304px;
		margin: 0 auto;
		margin-top: 32px;
	}
	${({ theme }) => theme.device.default} {
		width: 438px;
		margin: 0;
	}
	${({ theme }) => theme.device.desktop} {
		width: 644px;
	}
`;

export const HeroThumbMobile = styled(HeroThumb)`
	width: 100%;
	margin: auto;
	${({ theme }) => theme.device.tablet} {
		width: 259.2px;
		margin: 0;
	}
	${({ theme }) => theme.device.default} {
		width: 389.8px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 543px;
	}
`;
export const HeroDiscription = styled.p`
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	font-size: 14px;
	line-height: 19px;
	text-align: left;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	width: 100%;
	margin-bottom: 10px;
	${({ theme }) => theme.device.tablet} {
		width: 286px;
		text-align: left;
	}
	${({ theme }) => theme.device.default} {
		width: 335px;
		font-size: 15.6px;
		line-height: 21.2px;
		margin-bottom: 18px;
	}

	${({ theme }) => theme.device.desktop} {
		font-size: 18px;
		line-height: 27px;
		width: 100%;
		margin-bottom: 20px;
	}
`;

export const DownloadMobileDiscription = styled(HeroDiscription)`
	margin-bottom: 32px;
	text-align: left;
	${({ theme }) => theme.device.tablet} {
		margin-bottom: 32px;
		text-align: left;
	}

	${({ theme }) => theme.device.default} {
		margin-bottom: 32px;
		width: 405px;
		text-align: left;
	}

	${({ theme }) => theme.device.desktop} {
		margin-bottom: 38px;
		width: 436px;
	}
`;

export const HeroContainer = styled(Container)`
	&:not(:last-child) {
		margin-bottom: 60px;
	}
	${({ theme }) => theme.device.tablet} {
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* flex-direction: row-reverse; */
		&:not(:last-child) {
			flex-direction: row-reverse;
			margin-bottom: 80px;
			align-items: stretch;
		}
	}
	${({ theme }) => theme.device.default} {
		&:not(:last-child) {
			margin-bottom: 120px;
		}
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const DownloadList = styled.ul`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	text-align: center;
	${({ theme }) => theme.device.tablet} {
		display: block;
		text-align: left;
	}
	${({ theme }) => theme.device.default} {
	}
`;

export const DownloadItem = styled.li`
	&:not(:last-child) {
		margin-bottom: 16px;
	}
	${({ theme }) => theme.device.tablet} {
	}
	${({ theme }) => theme.device.default} {
		&:not(:last-child) {
			margin-bottom: 16px;
			margin-right: 0px;
		}
	}
`;

export const Link = styled.a`
	display: inline-block;
	width: 245px;
	font-family: ${({ theme }) => theme.fonts.descr.semiBold};
	padding: 14px 0;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	font-size: 16px;
	line-height: 1.25;

	background: ${({ theme }) => theme.colors.primary};

	${({ theme }) => theme.device.default} {
		text-align: center;
	}
`;

export const GlobalBox = styled.div`
	text-align: center;
	${({ theme }) => theme.device.tablet} {
		width: 310px;
		text-align: left;
	}
	${({ theme }) => theme.device.default} {
		width: 335px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 580px;
	}
`;
