import styled from 'styled-components';
import Leaf from '../../image/bg/leaf_bg.svg';
import Leaf2 from '../../image/bg/leaf2_bg.svg';
import Cloud from '../../image/bg/cloud_bg.svg';
import Cloud2 from '../../image/bg/cloud2_bg.svg';
import Cloud3 from '../../image/bg/cloud3_bg.svg';
import Sfera from '../../image/bg/sfera_bg.svg';
// import Down from '../../image/bg/cloudDown_bg.svg';

export const Hero = styled.section`
	padding: 100px 0px 60px;

	${({ theme }) => theme.device.tablet} {
		padding: 80px 0px 60px;
	}
	${({ theme }) => theme.device.default} {
		padding: 180px 0px 180px;
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
export const Section = styled.section`
	padding: 100px 0px 120px;

	${({ theme }) => theme.device.tablet} {
		padding: 120px 0px 160px;
		background-repeat: no-repeat;
		background-position: top left, center right, top 45% left, top 70% right,
			top 90% right, top 23% right;
		background-size: small;
		background-image: url(${Leaf}), url(${Leaf2}), url(${Cloud}), url(${Cloud2}),
			url(${Cloud3}), url(${Sfera});
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const Title = styled.h1`
	${({ theme }) => theme.testFonts.mobile.h1};
	text-align: center;
	margin: 0 auto;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h1};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h1};
		margin-bottom: 32px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h1};
		margin-bottom: 32px;
	}
`;

export const TitleLeave = styled.h2`
	text-align: center;
	margin: 40px auto 14px;
	font-family: ${({ theme }) => theme.fonts.title.bold};
	font-size: 25px;
	line-height: 1.2;
	color: ${({ theme }) => theme.colors.black_btn};
	${({ theme }) => theme.device.tablet} {
		font-size: 26px;
		margin-top: 50px;
	}
	${({ theme }) => theme.device.default} {
		font-size: 30px;
		margin-top: 60px;
		margin-bottom: 16px;
	}
	${({ theme }) => theme.device.desktop} {
		font-size: 45px;
		margin-top: 110px;
		margin-bottom: 32px;
	}
`;

export const Discription = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	margin-bottom: 50px;
	max-width: 260px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
		margin-bottom: 32px;
		max-width: 310px;
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
		max-width: 310px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
		max-width: 400px;
	}
`;
export const DiscriptionAc = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	color: ${({ theme }) => theme.colors.prim_fifteen};
	width: auto;
	margin-left: 22px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
		max-width: 250px;
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
		max-width: 270px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
		max-width: 350px;
	}
`;
export const DiscriptionLeave = styled.p`
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	font-size: 14px;
	text-align: center;
	line-height: 1.35;
	color: ${({ theme }) => theme.colors.primaryText};
	margin-top: 0px;
	margin-bottom: 30px;
	${({ theme }) => theme.device.tablet} {
	}
	${({ theme }) => theme.device.default} {
		font-size: 16px;
	}
	${({ theme }) => theme.device.desktop} {
		font-size: 18px;
	}
`;

export const Link = styled.a`
	margin-left: auto;
	margin-right: auto;
	${({ theme }) => theme.testFonts.mobile.body.bold};
	padding: 14px 24px;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.colors.primary};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.bold};
		padding: 14px 38px;
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.bold};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.bold};
	}
`;
export const ButDiv = styled.div`
	width: 100%;
	text-align: center;
	${({ theme }) => theme.device.tablet} {
		text-align: left;
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const GlobalBox = styled.div`
	width: auto;

	padding-right: 8px;
	padding-left: 8px;

	${({ theme }) => theme.device.tablet} {
		margin: 0 auto;
		width: 310px;
		text-align: left;

		padding-right: 10px;
		padding-left: 10px;
	}
	${({ theme }) => theme.device.default} {
		width: 416px;
		padding-left: 20px;
		padding-right: 20px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 539px;
		text-align: left;
		padding-right: 30px;
	}
`;
export const GlobalBoxFlex = styled.div`
	display: flex;
	text-align: baseline;
	width: auto;

	padding-right: 8px;
	padding-left: 8px;

	${({ theme }) => theme.device.tablet} {
		margin: 0 auto;
		width: 310px;
		text-align: left;
		align-items: start;
		padding-right: 10px;
		padding-left: 10px;
	}
	${({ theme }) => theme.device.default} {
		width: 416px;
		padding-right: 20px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 539px;
		padding-right: 30px;
	}
`;
export const GlobalBoxFlexAccord = styled.div`
	display: flex;
	text-align: left;
	margin-bottom: 20px;
	${({ theme }) => theme.device.tablet} {
		margin: auto;
		width: 285px;
		text-align: left;
		align-items: start;
		padding-right: 10px;
		padding-left: 10px;
	}
	${({ theme }) => theme.device.default} {
		width: 375px;
		padding-right: 20px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 465px;
		padding-right: 30px;
	}
`;
export const GlobalImage = styled.div`
	margin-bottom: 35px;

	${({ theme }) => theme.device.tablet} {
		width: 310px;
		margin-bottom: 0px;
	}
	${({ theme }) => theme.device.default} {
		width: 385px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 556px;
	}
`;
export const GlobalImageAccord = styled.div`
	margin-bottom: 25px;

	${({ theme }) => theme.device.tablet} {
		margin-bottom: 0px;
		width: 285px;
		align-items: center;
	}
	${({ theme }) => theme.device.default} {
		width: 375px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 465px;
	}
`;
export const YouTube = styled.iframe`
	margin: 0 auto;
	width: 327px;
	height: 183px;
	filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.4));
	${({ theme }) => theme.device.tablet} {
		width: 309px;
		height: 172px;
	}
	${({ theme }) => theme.device.default} {
		width: 350px;
		height: 194.94px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 444px;
		height: 247px;
	}
`;

export const Img = styled.img`
	margin: 0 auto;

	max-width: 327px;
	width: 100%;
	height: auto;
	filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.4));
	${({ theme }) => theme.device.tablet} {
		max-width: 309px;
	}
	${({ theme }) => theme.device.default} {
		max-width: 350px;
	}
	${({ theme }) => theme.device.desktop} {
		max-width: 444px;
	}
`;

export const Item = styled.li`
	:first-child {
		padding-top: 40px;
	}
	&:not(:last-child) {
		padding-bottom: 50px;
	}
	align-items: center;
	${({ theme }) => theme.device.tablet} {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		&:nth-child(2n + 2) {
			flex-direction: row-reverse;
		}
	}
	${({ theme }) => theme.device.default} {
		&:not(:last-child) {
			padding-bottom: 75px;
		}
	}
	${({ theme }) => theme.device.desktop} {
		&:not(:last-child) {
			padding-bottom: 100px;
		}
	}
`;

export const DownText = styled.a`
	text-decoration-line: underline;
	color: blue;
`;
export const NumberDiv = styled.div`
	display: flex;
	background: #f1f1f5;
	border-radius: 50%;
	align-items: center;
	text-align: center;
	width: 40px;
	height: 40px;
	margin-bottom: 32px;
	margin-right: 22px;
`;

export const Number = styled.p`
	font-family: ${({ theme }) => theme.fonts.body.regular};
	color: ${({ theme }) => theme.colors.prim_seventeen};
	font-size: 18px;
	line-height: 20px;
	margin: auto;
	width: 40px;
	${({ theme }) => theme.device.tablet} {
		font-size: 20px;
	}
	${({ theme }) => theme.device.default} {
		font-size: 20px;
	}
	${({ theme }) => theme.device.desktop} {
		font-size: 24px;
	}
`;

export const AccordLi = styled.li`
	padding: 30px 0 15px;
	${({ theme }) => theme.device.tablet} {
		padding: 60px 0 30px;
		display: flex;
		&:nth-child(2n + 1) {
			flex-direction: row-reverse;
		}
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
export const LLC = styled.h3`
	text-align: center;
	margin: 0 auto;
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	font-size: 12px;
	line-height: 1.22;
	margin-bottom: 40px;
	color: ${({ theme }) => theme.colors.placeholder};
`;
