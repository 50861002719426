import Helmet from 'react-helmet';
import EndHero from '../../components/EndEncryption';
import Address from 'components/Address';
import Main from 'components/Main';

import { ScrollToTop } from 'hooks/ScrollToTop';

const End = () => {
	ScrollToTop();
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu/end-to-end-encryption" />
			</Helmet>
			<Main>
				<EndHero />
			</Main>
			<Address />
		</>
	);
};
export default End;
