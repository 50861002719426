import styled from 'styled-components';
import {
	HeroContainer,
	HeroDiscription,
} from 'components/Downloads/DownloadHero/downloadHero.styled';
import { NavLink } from 'react-router-dom';

export const Container = styled(HeroContainer)`
	justify-content: center;
	&:not(:last-child) {
		flex-direction: row;
	}
`;
export const Box = styled.div`
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 24px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.colors.prim_eighteen};
	padding: 32px 24px;
	${({ theme }) => theme.device.tablet} {
		padding: 40px 32px;
	}
	${({ theme }) => theme.device.default} {
		padding: 64px 72px;
	}
`;

export const Separator = styled.div`
	width: 41px;
	height: 24px;
`;

export const Title = styled.h1`
	${({ theme }) => theme.tags.h1.mobile}
	text-align: left;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	margin-bottom: 16px;
	${({ theme }) => theme.device.tablet} {
		text-align: center;
		${({ theme }) => theme.tags.h1.tablet}
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.tags.h1.default}
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.tags.h2.desktop}
		margin-bottom: 24px;
	}
`;

export const Description = styled(HeroDiscription)`
	&:last-child {
		margin-bottom: 0px;
	}
	${({ theme }) => theme.device.tablet} {
		text-align: center;
	}
`;

export const Description2 = styled(Description)`
	color: ${({ theme }) => theme.colors.prim_fifteen};
`;

export const ExtensionButton = styled(NavLink)`
	padding: 9px 16px;
	width: 220px;
	background: ${({ theme }) => theme.colors.primary};
	color: ${({ theme }) => theme.colors.white};
	border-radius: 4px;
	margin-top: 24px;
	text-align: center;
	${({ theme }) => theme.device.default} {
		margin-top: 32px;
		margin-left: auto;
		margin-right: auto;
	}
	${({ theme }) => theme.device.desktop} {
		margin-top: 40px;
	}
`;

export const DownloadWrapper = styled.div`
	display: flex;
	justifycontent: center;
	flex-wrap: wrap;
`;

export const QRCode = styled.img`
	width: 152px;
	margin: auto;
`;

export const ButtonGroup = styled.ul`
	display: flex;
	flex-direction: column;
	alignitems: center;
	justify-content: center;
	margin: auto;
	margin-top: 16px;
	${({ theme }) => theme.device.tablet} {
		flex-direction: column;
	}
	${({ theme }) => theme.device.default} {
		flex-direction: row;
	}
	${({ theme }) => theme.device.desktop} {
		flex-direction: column;
	}
`;

export const Item = styled.div`
	margin-bottom: 16px;
	margin-left: 8px;
	margin-right: 8px;
`;
