import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ChakraProvider } from '@chakra-ui/react';
import { CookieConsentProvider } from './hooks/CookieHandler';
import App from './components/App/App';
import { theme } from './constants/theme';
import GlobalFonts from './fonts/fonts.styled';
import 'modern-normalize/modern-normalize.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	// <React.StrictMode>
	<CookieConsentProvider>
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<ChakraProvider>
					<GlobalFonts />
					<App />
				</ChakraProvider>
			</ThemeProvider>
		</BrowserRouter>
	</CookieConsentProvider>
	// </React.StrictMode>
);
