import styled from 'styled-components';

export const WrapHero = styled.div`
	order: 2;
	${({ theme }) => theme.device.tablet} {
		order: 1;
	}
`;
export const WrapIntro = styled.div`
	order: 1;
	${({ theme }) => theme.device.tablet} {
		order: 2;
	}
`;

export const OrderedSection = styled.div`
	display: flex;
	flex-direction: column;
`;
