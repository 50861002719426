import Helmet from 'react-helmet';
import Hero from 'components/Home/Hero/Hero_2';
import Toolbar from 'components/Home/Toolbar/Toolbar';
// import Introduction from 'components/Home/Introduction';
import Conference from 'components/Home/Conference/Conference';
import { ScrollToTop } from 'hooks/ScrollToTop';
import Address from 'components/Address';
import { OrderedSection, WrapHero,  } from './Home.styled';

const Home = () => {
	ScrollToTop();
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu/2" />
			</Helmet>
			<main>
				<OrderedSection>
					<WrapHero>
						<Hero />
					</WrapHero>
					{/* <WrapIntro>
						<Introduction />
					</WrapIntro> */}
				</OrderedSection>

				<Toolbar />
				<Conference />
				<Address />
			</main>
		</>
	);
};
export default Home;
