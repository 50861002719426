const topics = [
	{
		value: 'Registration is too long',
		content: 'Registration is too long',
	},
	{
		value: 'Too many permissions needed',
		content: 'Too many permissions needed',
	},
	{
		value: 'Lacking features',
		content: 'Lacking features (please suggest below)',
	},
	{
		value: 'User experience needs improving',
		content: 'User experience needs improving (please suggest below)',
	},
	{
		value: 'Other programs suits me better',
		content: 'Other programs suits me better (please let us know why)',
	},
];

export default topics;
