import styled from 'styled-components';
import { device } from '../../constants/mediaSize';
import { theme } from '../../constants';

export const ContainerWrapper = styled.div`
	display: none;
	position: fixed;
	width: 100%;
	overflow: hidden;
	bottom: 75px;
	pointer-events: none;
	${device.mobile} {
	}
	${device.tablet} {
		bottom: 105px;
	}
`;

export const CookieContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px 24px;
	background: ${theme.colors.primary};
	border-radius: 8px;
	width: 100%;
	bottom: 80px;
	max-width: 343px;
	pointer-events: auto;
	${device.mobile} {
	}
	${device.tablet} {
		bottom: 105px;
		max-width: 257px;
	}
`;

export const TitleWrapper = styled.div`
	display: flex;
	margin-bottom: 15px;
	${device.tablet} {
		margin-bottom: 15px;
	}
`;
export const Title = styled.p`
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: ${theme.colors.white};
	margin-left: 12px;
`;

export const DescriptionWrapper = styled.div`
	color: ${theme.colors.white};
`;
export const Description = styled.p`
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: ${theme.colors.white};
	margin-bottom: 15px;
`;

const Button = styled.button`
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	padding: 7px 16px;
	border-radius: 16px;
`;

export const AccepButton = styled(Button)`
	color: ${theme.colors.primary};
	background: ${theme.colors.white};
	margin-right: 12px;
`;

export const DenyButton = styled(Button)`
	color: ${theme.colors.white};
	background: ${theme.colors.primary};
	border: 1px solid #e3e3eb;
`;
