import styled from 'styled-components';

export const EndEncryptionSection = styled.section`
	padding: 100px 0px 232px;

	${({ theme }) => theme.device.tablet} {
		padding: 200px 0px 162px;
	}
	${({ theme }) => theme.device.default} {
		padding: 205px 0px 162px;
	}
	${({ theme }) => theme.device.desktop} {
		padding: 205px 0px 85px;
	}
`;
