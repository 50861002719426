import { useState, useRef, useEffect } from 'react';
import { FileDropZone, FileDropOverlay, Output } from './FileDrop.styled';
import removeFile from 'image/icons/close.svg';
import iconDoc from 'image/icons/icon-doc.png';
import iconDocx from 'image/icons/icon-docx.png';
import iconMp4 from 'image/icons/icon-mp4.png';
import iconPdf from 'image/icons/icon-pdf.png';
import iconPpt from 'image/icons/icon-ppt.png';
import iconTxt from 'image/icons/icon-txt.png';

const units = ['BYTES', 'KB', 'MB', 'GB', 'TB'];

const FileIcon = (props) => {
	const { iconProps } = props;
	const [tagType, updateTagType] = useState(null);
	const [iconSrc, updateIconSrc] = useState(null);
	const { alt, type, width } = iconProps;

	useEffect(() => {
		const { src, type } = iconProps;
		if (type.includes('image')) {
			updateTagType('image');
			updateIconSrc(src);
		} else if (type.includes('mp4')) {
			updateTagType('mp4');
			updateIconSrc(iconMp4);
		} else if (type.includes('quicktime')) {
			updateTagType('quicktime');
			updateIconSrc(src);
		} else if (type.includes('msword')) {
			updateTagType('image');
			updateIconSrc(iconDoc);
		} else if (type.includes('wordprocessingml')) {
			updateTagType('image');
			updateIconSrc(iconDocx);
		} else if (type.includes('pdf')) {
			updateTagType('image');
			updateIconSrc(iconPdf);
		} else if (type.includes('presentationml') || type.includes('powerpoint')) {
			updateTagType('image');
			updateIconSrc(iconPpt);
		} else if (type.includes('text/plain')) {
			updateTagType('image');
			updateIconSrc(iconTxt);
		} else {
			updateTagType(null);
			updateIconSrc(null);
		}
	}, [iconProps]);
	return (
		<>
			{tagType === 'image' && (
				<img width={width} src={iconSrc} alt={alt} type={type} />
			)}
			{tagType === 'quicktime' && (
				<video width={width} src={iconSrc} alt={alt} type={type} />
			)}
			{tagType === 'mp4' && (
				<video
					autoPlay
					width={width}
					src={iconSrc}
					alt={alt}
					type={type}
					poster={iconMp4}
				/>
			)}
		</>
	);
};

const DropZone = (props) => {
	const { name, handler: formik } = props;

	const inputRef = useRef(null);
	const [dragActive, setDragActive] = useState(false);
	const [fileSize, updateFileSize] = useState(null);
	const [iconProps, updateIconProps] = useState({});

	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};
	const handleDrop = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			formik.setFieldValue(name, e.dataTransfer.files[0]);
		}
	};
	const handleDropChange = function (e) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			formik.setFieldValue(name, e.target.files[0]);
		} else {
			formik.setFieldValue(name, null);
		}
	};

	useEffect(() => {
		if (formik.values[name]) {
			let i = -1,
				size = Number(formik.values[name].size || 0),
				value = size;
			while (size > 0) {
				i++;
				value = parseInt(size / 1024) ? (size / 1024).toFixed(2) : value;
				size = Math.floor(size / 1024);
			}
			updateFileSize(value ? value + ' ' + units[i] : null);

			const icon = {
				src: URL.createObjectURL(formik.values[name]),
				alt: formik.values[name].name,
				type: formik.values[name].type,
			};
			updateIconProps(icon);
		}
	}, [formik.values[name]]);

	const handleUploadButton = (e) => {
		e.preventDefault();
		inputRef.current.click();
	};

	const handleRemoveFile = (e) => {
		e.preventDefault();
		formik.setFieldValue(name, '');
	};

	return (
		<>
			{formik.values.attachment && (
				<Output>
					<div className="file-detail">
						{iconProps.src && (
							<FileIcon iconProps={{ ...iconProps, width: '57px' }} />
						)}
						<div className="file-text-detail">
							<p>{formik.values.attachment.name}</p>
							<p>{fileSize}</p>
						</div>
					</div>
					<button className="remove" onClick={handleRemoveFile}>
						<img src={removeFile} alt="remove file" width={8} height={8} />
					</button>
				</Output>
			)}
			<FileDropZone
				id="label-file-upload"
				htmlFor={name}
				onDragEnter={handleDrag}
				className={formik.errors[name] ? 'invalid-input' : ''}
			>
				<input
					ref={inputRef}
					type="file"
					id={name}
					name={name}
					onChange={handleDropChange}
				/>
				<button onClick={handleUploadButton}>Upload File here</button>
				{dragActive && (
					<FileDropOverlay
						id="drag-file-element"
						onDragEnter={handleDrag}
						onDragLeave={handleDrag}
						onDragOver={handleDrag}
						onDrop={handleDrop}
					/>
				)}
			</FileDropZone>
		</>
	);
};
export default DropZone;
