import styled from 'styled-components';
// import Container from '../Container';

export const DownloadLink = styled.a`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: start;
	width: 290px;
	height: 48px;
	font-family: ${({ theme }) => theme.fonts.descr.semiBold};
	padding: 14px;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	margin: auto;
	font-size: 16px;
	line-height: 20px;
	background: ${({ theme }) => theme.colors.primary};
	cursor: pointer;
	padding-left: 25px;
	${({ theme }) => theme.device.tablet} {
		margin: 0px;
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const DownloadButton = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: start;
	width: 290px;
	height: 48px;
	font-family: ${({ theme }) => theme.fonts.descr.semiBold};
	padding: 14px;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	margin: auto;
	font-size: 16px;
	line-height: 20px;
	background: ${({ theme }) => theme.colors.primary};
	cursor: pointer;
	padding-left: 25px;
	${({ theme }) => theme.device.tablet} {
		margin: 0px;
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const PopupBox = styled.div`
	position: absolute;
	background: #fff;
	box-shadow: 0px 14px 32px rgba(112, 88, 255, 0.16);
	border-radius: 10px;
	display: none;
	flex-direction: column;
	justify-content: space-around;
	padding: 15px;
	z-index: 2;
	top: 100%;
	min-width: 100%;
	margin: auto;
	${DownloadButton}:hover & {
		display: flex;
	}
	&:hover {
		display: flex;
	}
	${({ theme }) => theme.device.tablet} {
		left: 0;
		justify-content: flex-start;
	}
`;

export const PopLink = styled.a`
	font-family: ${({ theme }) => theme.fonts.descr.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	font-size: 14px;
	line-height: 19px;
	margin-bottom: 15px;
	width: 100%;
	&.active {
		font-family: ${({ theme }) => theme.fonts.descr.bold};
	}
	&:last-child {
		margin-bottom: 0;
	}
	${({ theme }) => theme.device.tablet} {
		font-size: 14px;
	}
`;

export const DownloadList = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 44px;
	width: 290px;
	${({ theme }) => theme.device.tablet} {
		margin-top: 24px;
		align-items: start;
	}
	${({ theme }) => theme.device.default} {
		margin-top: 32px;
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const DownloadItem = styled.li`
	margin-bottom: 24px;
	&:last-child {
		margin-bottom: 0;
	}
	${({ theme }) => theme.device.tablet} {
		// margin-top: 24px;
	}
	${({ theme }) => theme.device.default} {
		// margin-top: 32px;
	}
	${({ theme }) => theme.device.desktop} {
		&:last-child {
			margin-bottom: 24px;
		}
	}
`;

export const Link = styled.a`
	display: inline-block;
	width: 245px;
	font-family: ${({ theme }) => theme.fonts.descr.semiBold};
	padding: 14px 0;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	font-size: 16px;
	line-height: 1.25;

	background: ${({ theme }) => theme.colors.primary};

	${({ theme }) => theme.device.default} {
		text-align: center;
	}
`;
