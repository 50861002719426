import Helmet from 'react-helmet';
import TermsHero from 'components/TermsHero';
import Address from 'components/Address';
import Main from 'components/Main';
import { ScrollToTop } from 'hooks/ScrollToTop';

const Terms = () => {
	ScrollToTop();
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu/terms" />
			</Helmet>
			<Main>
				<TermsHero />
			</Main>
			<Address />
		</>
	);
};

export default Terms;
