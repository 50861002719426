import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import CustomSelect from 'components/Select';
import FileDrop from 'components/FileDrop';
import {
	InputWrapper,
	ModalInput,
	ModaButtonSend,
	FormTitle,
	InputName,
	ModalText,
	ErrorMessage,
	SuccessMessage,
} from './ReportForm.styled';
import { useFormValidator } from 'hooks/FormValidator';
import { theme } from 'constants/theme';
import exclamationIcon from 'image/icons/exclamation_red.png';
import axios from 'axios';
import { topicsList } from 'data/faq';

const ReportForm = (props) => {
	const [searchParams] = useSearchParams();
	const { updateTopic } = props;
	const options = topicsList.map((category) => ({
		label: category,
		value: category,
	}));
	const [selectState, resetSelectState] = useState(0);
	let initialValues = {
		subject: '',
		email: '',
		description: '',
		topic: options[0].value,
		attachment: '',
	};
	const [disableSubmit, updateSubmitState] = useState(0);
	const [successMessage, setSuccessMessage] = useState(false);

	const onSubmit = async (values, helper) => {
		try {
			const { subject, email, description, topic, attachment } = values;
			const data = {
				subject: subject.replace(/\s\s+/g, ' ').trim(),
				email: email.replace(/\s\s+/g, ' ').trim(),
				description: description.replace(/\s\s+/g, ' ').trim(),
				topic: topic.replace(/\s\s+/g, ' ').trim(),
			};
			if (attachment) {
				data['attachment'] = attachment;
			}

			await axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/public/feedback`,
				data,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			helper.resetForm();
			setTimeout(() => setSuccessMessage(true), 500);
		} catch (e) {
			console.log(e);
		}
	};
	let formik = useFormValidator(initialValues, onSubmit);

	useEffect(() => {
		updateSubmitState(!!Object.keys(formik.errors).length);
	}, [formik.errors]);

	useEffect(() => {
		updateTopic(formik.values.topic);
	}, [formik.values.topic]);

	useEffect(() => {
		setSuccessMessage(false);
	}, [formik.values]);

	useEffect(() => {
		const qEmail = searchParams.get('email');
		if (qEmail) {
			formik.setFieldValue('email', qEmail);
		}
	}, []);

	return (
		<>
			<FormTitle>Report a Problem</FormTitle>
			<form onSubmit={formik.handleSubmit}>
				<InputWrapper>
					<InputName>Select a topic</InputName>
					<CustomSelect
						name="topic"
						defaultValue={{ ...options[0] }}
						options={options}
						handler={formik}
						reset={selectState}
						onBlur={formik.handleBlur}
					/>
					{formik.errors.topic && formik.touched.topic && (
						<ErrorMessage>
							<span>{formik.errors.topic}</span>
						</ErrorMessage>
					)}
				</InputWrapper>

				<InputWrapper>
					<InputName>Email Address</InputName>
					<ModalInput
						type="text"
						name="email"
						value={formik.values.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className={
							formik.errors.email && formik.touched.email ? 'invalid-input' : ''
						}
					/>
					{formik.errors.email && formik.touched.email && (
						<ErrorMessage>
							<span>{formik.errors.email}</span>
						</ErrorMessage>
					)}
				</InputWrapper>

				<InputWrapper>
					<InputName>Subject</InputName>
					<ModalInput
						type="text"
						name="subject"
						value={formik.values.subject}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className={
							formik.errors.subject && formik.touched.subject
								? 'invalid-input'
								: ''
						}
					/>
					{formik.errors.subject && formik.touched.subject && (
						<ErrorMessage>
							<span>{formik.errors.subject}</span>
						</ErrorMessage>
					)}
				</InputWrapper>

				<InputWrapper>
					<InputName>Description</InputName>
					<ModalText
						rows="3"
						type="text"
						name="description"
						value={formik.values.description}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className={
							formik.errors.description && formik.touched.description
								? 'invalid-input'
								: ''
						}
					/>
					{formik.errors.description && formik.touched.description && (
						<ErrorMessage>
							<span>{formik.errors.description}</span>
						</ErrorMessage>
					)}
				</InputWrapper>

				<InputWrapper>
					<InputName>Attachments (optional)</InputName>
					{formik.errors.attachment && (
						<ErrorMessage
							style={{
								display: 'flex',
								padding: '8px',
								borderWidth: '1px',
								borderStyle: 'solid',
								fontSize: '12px',
								border: `1px solid ${theme.colors.sec_eleven}`,
							}}
						>
							<img
								src={exclamationIcon}
								width={'16'}
								height={'16'}
								alt="Invalid file"
								style={{
									margin: 'auto 0',
								}}
							/>
							<span
								style={{
									paddingLeft: '5px',
									margin: 'auto 0',
								}}
							>
								{formik.errors.attachment}
							</span>
						</ErrorMessage>
					)}
					<FileDrop name={'attachment'} handler={formik} />
				</InputWrapper>

				<div style={{ display: 'flex' }}>
					<ModaButtonSend type="submit" disabled={disableSubmit}>
						Send
					</ModaButtonSend>

					{successMessage && <SuccessMessage>Request sent</SuccessMessage>}
				</div>
			</form>
		</>
	);
};

export default ReportForm;
