import styled from 'styled-components';
import { device, size } from 'constants/mediaSize';
import top from 'image/bg/top_form_page.png';
import providerLeaf from 'image/bg/leaf_providers.svg';
import vector24 from 'image/bg/vector_24.svg';

export const mainBackgroundStyle = {
	background: `top left / 100% 500px no-repeat url(${top}),
left 0px top 910px / 160px auto no-repeat url(${providerLeaf}),
right 0px top 810px / 160px auto no-repeat url(${vector24})`,
};

export const Container = styled.div`
	width: 100%;
	max-width: 100%;
	padding: 0 16px;
	padding-top: 45px;
	margin: 0 auto;
	margin-top: 55px;
	${device.mobile} {
		max-width: ${size.mobile};
	}
	${device.tablet} {
		padding-top: 100px;
		margin-top: 70px;
		max-width: ${size.tablet};
	}
	${device.default} {
		max-width: ${size.default};
	}
	${device.desktop} {
		max-width: 1140px;
	}
`;

export const Section = styled.div`
	display: flex;
	margin: 0 auto;
	justify-content: center;
`;

export const FormWrapper = styled.div`
	width: 100%;
	max-width: 350px;
	border-radius: 8px;
	margin-bottom: 90px;
	${({ theme }) => theme.device.default} {
		max-width: 360px;
	}
`;
