import styled from 'styled-components';

export const FAQSection = styled.section`
	padding-top: 80px;
	padding-bottom: 30px;
	${({ theme }) => theme.device.tablet} {
		padding-bottom: 40px;
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const ContainerFAQ = styled.div`
	margin: 0 auto;
	padding: 0 15px;
	max-width: 343px;

	${({ theme }) => theme.device.tablet} {
		max-width: unset;
	}

	${({ theme }) => theme.device.default} {
		padding: 0 24px;
		// max-width: 830px;
	}
	${({ theme }) => theme.device.desktop} {
		// max-width: 1140px;
	}
`;

export const SectionTitle = styled.h1`
	${({ theme }) => theme.testFonts.mobile.h1};
	text-align: center;
	margin-bottom: 8px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h1};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h1};
		padding-top: 100px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h1};
		padding-top: 132px;
	}
`;

export const SectionTitleSub = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	text-align: center;
	padding-bottom: 30px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
		padding-bottom: 30px;
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
		padding-bottom: 40px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
		padding-bottom: 40px;
	}
`;

export const FAQdiv = styled.div`
	margin: 0 auto;
`;
export const FAQul = styled.ul``;
export const FAQli = styled.li`
	width: 343;
	/* padding-top: 24px;
  padding-bottom: 24px; */
	&:not(:last-child) {
		margin-bottom: 48px;
	}

	${({ theme }) => theme.device.tablet} {
		/* padding-top: 28px;
    padding-bottom: 34px; */
		&:not(:last-child) {
			margin-bottom: 62px;
		}
	}
	${({ theme }) => theme.device.default} {
		/* padding-top: 32px; */
		padding-bottom: 40px;
		&:not(:last-child) {
			margin-bottom: 72px;
		}
	}
`;
