import {
	PartnersDiv,
	PartnersUl,
	PartnersLi,
	ProTitle,
	ProDescription,
	GlobalSection,
	Image,
	Date,
} from './Providers.styled';
import Container from '../../Container/Container.styled';
import GoogleMeet from '../../../image/providers/google.svg';
import Microsoft from '../../../image/providers/MS.svg';
import Webex from '../../../image/providers/webex.svg';
import Zoom from '../../../image/providers/zoom.svg';
import Avaya from '../../../image/providers/AvayaStar.svg';
import Bluejeans from '../../../image/providers/bluejeans.svg';
import Huawei from '../../../image/providers/huawei.svg';
import StarLeaf from '../../../image/providers/starleaf.svg';
import GoTo from '../../../image/providers/gotoStar.svg';
import LifeSize from '../../../image/providers/LifiSizeStar.svg';

const Provider = () => {
	return (
		<GlobalSection style={{ display: 'flex' }}>
			<Container style={{ padding: 0 }}>
				<PartnersDiv>
					<ProTitle>Uniform Video Conference Booking</ProTitle>
					<ProDescription>
						SEEYOU works with all video conference- and learning platforms and
						provides a simplified uniform booking interface for Google Meet,
						Microsoft Teams, WebEx, and Zoom, with more to follow. Pathways and
						instructions for booking Avaya, BlueJeans, Huawei, Lifesize, GoTo,
						and StarLeaf meetings are also available
					</ProDescription>

					<PartnersUl>
						<PartnersLi>
							<Image src={GoogleMeet} htmlWidth="105px" alt="GoogleMeet" />
						</PartnersLi>
						<PartnersLi>
							<Image src={Microsoft} htmlWidth="106px" alt="Microsoft" />
						</PartnersLi>
						<PartnersLi>
							<Image src={Webex} htmlWidth="87px" alt="Webex" />
						</PartnersLi>
						<PartnersLi>
							<Image src={Zoom} htmlWidth="79px" alt="Zoom" />
						</PartnersLi>
					</PartnersUl>
					<PartnersUl>
						<PartnersLi>
							<Image src={Avaya} htmlWidth="62px" alt="Avaya" />
						</PartnersLi>
						<PartnersLi>
							<Image src={Bluejeans} htmlWidth="78px" alt="BlueJeans" />
						</PartnersLi>
						<PartnersLi>
							<Image src={Huawei} htmlWidth="93px" alt="Huawei" />
						</PartnersLi>
						<PartnersLi>
							<Image src={LifeSize} htmlWidth="87px" alt="LifeSize" />
						</PartnersLi>
						<PartnersLi>
							<Image src={GoTo} htmlWidth="81px" alt="GoTo" />
						</PartnersLi>
						<PartnersLi>
							<Image src={StarLeaf} htmlWidth="84px" alt="StarLeaf" />
						</PartnersLi>
					</PartnersUl>
				</PartnersDiv>
				<Date>
					<sup>*</sup>September 1st, 2023
				</Date>
			</Container>
		</GlobalSection>
	);
};

export default Provider;
