import styled from 'styled-components';

import Cloud from '../../../image/bg/cloud_bg.svg';
import Cloud2 from '../../../image/bg/cloud2_bg.svg';
import Cloud3 from '../../../image/bg/cloud3_bg.svg';
import Sfera from '../../../image/bg/sfera_bg.svg';

export const Section = styled.section`
	padding: 30px 0px 131px;
	${({ theme }) => theme.device.tablet} {
		padding: 40px 0px 64px;
		background-repeat: no-repeat;
		background-position: top 1000px left, top 1400px right, top 2400px right,
			top 250px right;
		background-size: small;
		background-image: url(${Cloud}), url(${Cloud2}), url(${Cloud3}),
			url(${Sfera});
	}
	${({ theme }) => theme.device.default} {
		padding: 50px 0px 81px;
	}
	${({ theme }) => theme.device.desktop} {
		padding: 60px 0px 127px;
	}
`;

export const HiddenTitle = styled.h2`
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;

	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
`;

export const DiscriptionSub = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.small};
	text-align: start;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.small};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.small};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.small};
	}
`;

export const SubTitle = styled.h1`
	${({ theme }) => theme.testFonts.mobile.h1};
	margin-bottom: 16px;
	text-align: left;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.h1};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.h1};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.h1};
	}
`;

export const Discription = styled.p`
	${({ theme }) => theme.testFonts.mobile.body.regular};
	color: ${({ theme }) => theme.colors.prim_sixteen};
	margin-bottom: 24px;
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.tablet.body.regular};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.mDesktop.body.regular};
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
	}
`;

export const DiscriptionWithMargin = styled(Discription)`
	margin-bottom: 27px;
`;

export const ConferenceItem = styled.li`
	align-items: center;
	&:not(:last-child) {
		margin-bottom: 80px;
	}
	${({ theme }) => theme.device.tablet} {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		&:nth-child(2n + 1) {
			flex-direction: row-reverse;
		}
		&:not(:last-child) {
			margin-bottom: 60px;
		}
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
		&:not(:last-child) {
			margin-bottom: 70px;
		}
	}
`;

export const BrandsLogos = styled.img`
	${({ theme }) => theme.device.tablet} {
		width: 310px;
	}
	${({ theme }) => theme.device.default} {
		width: 402px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 570px;
	}
`;
export const DiscriptionList = styled.ul`
	list-style-type: disc;
	padding-left: 27px;
	margin-bottom: 24px;
	${({ theme }) => theme.device.tablet} {
		margin-bottom: 32px;
	}
	${({ theme }) => theme.device.default} {
		margin-bottom: 50px;
	}
	${({ theme }) => theme.device.desktop} {
		margin-bottom: 70px;
	}
`;

export const BoxBeforEnd = styled.div`
	margin: 0 auto;
	margin-bottom: 102px;
`;
