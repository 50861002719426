import Helmet from 'react-helmet';
import SuggestForm from '../../components/Suggest/SuggestForm';
import { ScrollToTop } from 'hooks/ScrollToTop';
import Address from 'components/Address';
import Main from 'components/Main';
import {
	Container,
	Section,
	FormWrapper,
	mainBackgroundStyle,
} from './Suggest.styled';

const Suggest = () => {
	ScrollToTop();
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu/feature" />
			</Helmet>
			<Main style={{ ...mainBackgroundStyle }}>
				<Container>
					<Section>
						<FormWrapper>
							<SuggestForm />
						</FormWrapper>
					</Section>
				</Container>
			</Main>
			<Address />
		</>
	);
};
export default Suggest;
