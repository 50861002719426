import styled from 'styled-components';

export const Section = styled.section`
	margin-bottom: 40px;

	${({ theme }) => theme.device.tablet} {
		margin-bottom: 58px;
	}
	${({ theme }) => theme.device.default} {
		margin-bottom: 80px;
	}
	${({ theme }) => theme.device.desktop} {
		margin-bottom: 104px;
	}
`;
export const Questions = styled.p`
	${({ theme }) => theme.testFonts.tablet.body.bold};
	text-align: left;
	padding-top: 16px;
	padding-bottom: 16px;
	color: ${({ theme }) => theme.colors.prim_sixteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.desktop.body.bold};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.desktop.body.bold};
		padding-top: 32px;
		padding-bottom: 32px;
		padding-left: 30px;
		padding-right: 40px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.bold};
	}
`;
export const Answer = styled.div`
	${({ theme }) => theme.testFonts.tablet.body.regular};
	text-align: left;
	color: ${({ theme }) => theme.colors.prim_fifteen};
	${({ theme }) => theme.device.tablet} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
	}
	${({ theme }) => theme.device.default} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
		padding-top: 2px;
		padding-bottom: 32px;
		padding-left: 30px;
		padding-right: 40px;
	}
	${({ theme }) => theme.device.desktop} {
		${({ theme }) => theme.testFonts.desktop.body.regular};
	}
`;
export const PartnersDiv = styled.div`
	padding-top: 20px;
	width: 333px;
	${({ theme }) => theme.device.tablet} {
		width: 700px;
		margin-left: 20px;
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
export const PartnersUl = styled.ul`
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	${({ theme }) => theme.device.tablet} {
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;
export const PartnersLi = styled.li`
	padding: 0;
	margin-bottom: 24px;
	margin-right: 12px;

	transform: scale(80%);
	${({ theme }) => theme.device.tablet} {
		transform: scale(100%);
		margin-bottom: 32px;
		&:not(:last-child) {
			margin-right: 24px;
		}
	}
	${({ theme }) => theme.device.default} {
	}
	${({ theme }) => theme.device.desktop} {
	}
`;

export const Link = styled.a`
	color: ${({ theme }) => theme.colors.primary};
`;
