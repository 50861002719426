import {
	Title,
	Discription,
	Link,
	GlobalBox,
	ButDiv,
} from '../More/More.styled';
import { TermsSection } from './TermsHero.styled';
import Container from '../Container/Container.styled';
import TermsPDF from '../../PDF/SEEYOU_TERMS_OF_SERVICE.pdf';

const TermsHero = () => {
	return (
		<>
			<TermsSection>
				<Container>
					<GlobalBox>
						<Title>Terms of Service</Title>
						<Discription>
							SEEYOU's chat, video and voice calls are end-to-end encrypted,
							meaning they're genuinely private.
						</Discription>
						<Discription>
							SEEYOU cannot read the content nor see with whom you are
							communicating. Nor can anyone else. Unlike many other providers,
							this holds for group chats too. The encryption key is personal and
							managed by your mobile phone, and logs are not centrally stored by
							SEEYOU but are yours alone. The chat, video, and voice calls work
							equally well outside video conferences.
						</Discription>
						<ButDiv>
							<Link
								rel="nofollow"
								href={TermsPDF}
								target="_blank"
								style={{ display: 'inline-block' }}
							>
								Terms of Service
							</Link>
						</ButDiv>
					</GlobalBox>
				</Container>
			</TermsSection>
		</>
	);
};
export default TermsHero;
