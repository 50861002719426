import Helmet from 'react-helmet';
import DeleteHero from '../../components/DeleteAccount';
import Address from 'components/Address';
import Main from 'components/Main';

import { ScrollToTop } from 'hooks/ScrollToTop';

const Delete = () => {
	ScrollToTop();
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu/delete-account" />
			</Helmet>
			<Main>
				<DeleteHero />
			</Main>
			<Address />
		</>
	);
};
export default Delete;
