import styled from 'styled-components';

export const DownloadMobileList = styled.ul`
	${({ theme }) => theme.device.tablet} {
		display: flex;
	}
`;

export const DownloadMobileItem = styled.li`
	&:not(:last-child) {
		margin-bottom: 32px;
	}
	${({ theme }) => theme.device.tablet} {
		&:not(:last-child) {
			margin-bottom: 0px;
			margin-right: 24px;
		}
	}

	${({ theme }) => theme.device.desktop} {
		&:not(:last-child) {
			margin-right: 48px;
		}
	}
`;

export const DownloadMobileLink = styled.a`
	display: block;
	width: 190px;
	margin: 0 auto;
	${({ theme }) => theme.device.tablet} {
		width: 140px;
	}

	${({ theme }) => theme.device.desktop} {
	}
`;
