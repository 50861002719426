import Helmet from 'react-helmet';
import PrivacyHero from 'components/PrivacyHero';
import Address from 'components/Address';
import Main from 'components/Main';
import { ScrollToTop } from 'hooks/ScrollToTop';

const Privacy = () => {
	ScrollToTop();
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu/privacy" />
			</Helmet>
			<Main>
				<PrivacyHero />
			</Main>
			<Address />
		</>
	);
};

export default Privacy;
