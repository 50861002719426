import { useState, useEffect } from 'react';

export const usePlatformSelector = () => {
	const [os, updateOs] = useState(null);

	useEffect(() => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		const getOs = userAgent.toLowerCase();
		if (
			getOs.indexOf('iphone') > -1 ||
			getOs.indexOf('ipad') > -1 ||
			getOs.indexOf('ipod') > -1
		) {
			updateOs('ios');
		} else if (getOs.indexOf('win') > -1) {
			updateOs('win');
		} else if (getOs.indexOf('mac') > -1) {
			updateOs('mac');
		} else if (getOs.indexOf('android') > -1) {
			updateOs('android');
		}
	}, []);
	return os;
};
