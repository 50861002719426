import styled from 'styled-components';

export const GlobalGoalsUl = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
`;

export const GlobalGoalsLi = styled.li`
	margin-top: 6px;
	width: 74px;

	${({ theme }) => theme.device.tablet} {
	}
	${({ theme }) => theme.device.default} {
		width: 94px;
	}
	${({ theme }) => theme.device.desktop} {
		width: 124px;
	}
`;

export const Image = styled.img`
	width: 100%;
`;
